import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  semAnalyticsData: {
    application_history: null,
    re_enrollment_history: null,
    total_enrollment_history: null,
    google_analytics: null,
    facebook: null,
    finder: null,
    finder_by_location: null,
    applications_with_reputations: null,
    applications_by_program: null,
    applications_by_program_last_year: null,
    applications_by_program_year_over_year: null,
    zones: null,
    applications_by_program_raw: null,
    applications_by_program_by_program: null,
    applications_by_program_by_zone_school: null,
    applications_by_program_by_program_filtered_by_zone_school: null,
    applications_by_program_by_program_type_filtered_by_zone_school: null,
    applications_by_program_by_school_filtered_by_zone_school: null,
    schools: null,
    grades: null,
    programs: null,
    program_types: null,
  },
};
export const slice = createSlice({
  name: 'semAnalytics',
  initialState,
  reducers: {
    semAnalyticsOneDataRequested: (state, action) => {
      const key = action.payload.type;

      state.semAnalyticsData[key] = null; // started means key exists but value is null. once it's returned or errored there will be a non-null value there
    },
    semAnalyticsOneDataFinished: (state, action) => {
      const key = Object.keys(action.payload)[0];

      state.semAnalyticsData[key] = action.payload[key];
    },

    resetState: () => initialState,
  },
});

export const {
  semAnalyticsOneDataRequested,
  semAnalyticsOneDataFinished,
  resetState,
} = slice.actions;

export const selectSemAnalyticsData = (key) => (state) => state.semAnalytics.semAnalyticsData[key];
export default slice.reducer;
