import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'configs/theme';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Router } from 'react-router-dom';
import { Layout, ErrorHandler, UnsavedChangesPrompt } from 'components';
import history from 'configs/history';
import { selectIsLoading } from 'store/app/slice';
import { useSelector } from 'react-redux';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import { selectOrganization, selectUser } from 'store/auth/slice';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // .2 == 20% of users report performance analytics
  tracesSampleRate: 0.2,
  environment: process.env.NODE_ENV,
  enabled: window.location.hostname !== "localhost" // disable locally only
});

function App() {
  const isLoading = useSelector(selectIsLoading);
  const organization = useSelector(selectOrganization);
  const user = useSelector(selectUser) || {};

  // tag error events with user
  Sentry.setTag("user_id", user?.id);

  React.useEffect(() => {
    // tag error events with org
    Sentry.setTag("organization_id", organization?.id);
    Sentry.setTag("organization_name", organization?.name);

    if (organization && organization.name) {
      document.title = `${organization?.name} | Insights By SchoolMint`;
    } else {
      document.title = 'Insights By SchoolMint';
    }
  }, [organization]);

  return (
    <ThemeProvider theme={{ ...theme }}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <Router history={history}>
          <Layout isLoading={isLoading}>
            <ErrorHandler />
            <Routes />
            <UnsavedChangesPrompt />
          </Layout>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
