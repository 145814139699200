import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Auth from 'services/auth';
import {
  deleteAllSavedLayouts
} from 'common/helpers/layout';

import HeaderMenu from './HeaderMenu';

const MyAccountSelector = () => {
  const myAccountClick = (key, e) => {
    const actions = {
      signOut() {
        Auth.logOut();

        window.location.href = '/logout';
      },
      restoreDefaultLayout() {
        deleteAllSavedLayouts();

        window.location.href = '/';
      },
    };
    return actions[key]();
  };

  const myAccountOpts = {
    restoreDefaultLayout: 'Restore Default Layout',
    signOut: 'Sign-Out',
  };

  const buttonContent = () => (
    <>
      <AccountCircleIcon />
    </>
  );

  return (
    <HeaderMenu
      items={myAccountOpts}
      buttonContent={buttonContent()}
      onClick={myAccountClick}
    />
  );
};

MyAccountSelector.propTypes = {
};

MyAccountSelector.defaultProps = {
};

export default MyAccountSelector;
