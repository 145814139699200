import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  autocompleteWhite: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
  },
}));

export default Style;
