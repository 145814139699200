import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  adapters: [],
  loadingError: null,
  connectionAdapters: null,
  clientAdapter: null,
};

const slice = createSlice({
  name: 'adapters',
  initialState,
  reducers: {
    loadAdapters: (state) => {
      state.isLoading = true;
    },
    loadAdaptersSucceeded: (state, action) => {
      state.isLoading = false;
      state.adapters = action.payload.adapters;
      state.clientAdapter = action.payload.adapters.find(
        (adapter) => adapter.id == action.payload.userData.data.session.client
      );
    },
    loadAdaptersByConnection: (state) => {
      state.isLoading = true;
    },
    loadAdaptersByConnectionSucceeded: (state, action) => {
      state.isLoading = false;
      state.connectionAdapters = action.payload.adapters;
    },
    loadAdapterFailed: (state, action) => {
      state.isLoading = false;
      state.connectionAdapters = null;
      state.loadingError = action.payload.errorMessage;
    },
    resetConnectionAdapters: (state) => {
      state.connectionAdapters = null;
    },
  },
});

export const {
  loadAdapters,
  loadAdaptersSucceeded,
  loadAdaptersByConnection,
  loadAdaptersByConnectionSucceeded,
  loadAdapterFailed,
  resetConnectionAdapters
} = slice.actions;

export const selectIsLoading = (state) => state.adapters.isLoading;
export const selectAdapters = (state) => state.adapters.adapters;
export const selectClientAdapter = (state) => state.adapters.clientAdapter;
export const selectConnectionAdapters = (state) =>
  state.adapters.connectionAdapters;
export const selectLoadingError = (state) => state.adapters.loadingError;

export default slice.reducer;
