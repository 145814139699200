import React, { lazy } from 'react';
import { Progress, PrivateRoute } from 'components';
import { Route, Switch, Redirect } from 'react-router-dom';

const Routes = () => {
  return (
    <React.Suspense fallback={<Progress />}>
      <Switch>
        {/* TODO: PrivateRoute */}
        <PrivateRoute
          exact
          path="/"
          component={lazy(() => import('pages/InsightsDashboard'))}
        />
        <Route
          exact
          path="/welcome"
          component={lazy(() => import('pages/Welcome'))}
        />
        <Route
          exact
          path="/logout"
          component={lazy(() => import('pages/Logout'))}
        />
        <Route
          exact
          path="/auth/:loginInfo"
          component={lazy(() => import('pages/Auth'))}
        />
        <Redirect from="*" to="/" />
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
