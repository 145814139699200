import { extend, keyBy } from 'lodash';
  
const layout = {};

// TODO save to user prefs? or district-wide prefs? need product on this one
export const localStorageKey = 'sm-admin-dashboard-layout';
export const districtTabKey = 'district';
export const schoolTabKey = 'school';
export const programTypeTabKey = 'program_type';
export const finderTabKey = 'finder';
export const tabs = [
  {
    key: districtTabKey,
    label: 'District',
  },
  {
    key: schoolTabKey,
    label: 'School',
  },
  {
    key: programTypeTabKey,
    label: 'Program Type',
  },
  {
    key: finderTabKey,
    label: 'Finder',
  },
];

export const deleteAllSavedLayouts = () => {
    if (global.localStorage) {
        for (let tab of tabs) {
            global.localStorage.removeItem(`${localStorageKey}-${tab.key}`);
        }
    }
};

export const getSavedLayout = (tabKey) => {
  let gridLayout = null;

  if (global.localStorage) {
    const gridLayoutJSON = global.localStorage.getItem(`${localStorageKey}-${tabKey}`);
    if (gridLayoutJSON) {
      gridLayout = JSON.parse(gridLayoutJSON);
    }
  }

  return gridLayout;
};

export const saveLayout = (tabKey, gridLayout) => {
  // TODO: decide if this is a temporary or permanent solution
  //  - using layouts as keyed objects with data-grid properties
  //    instead of an array passable to ResponsiveGridLayout,
  //    so that we can use them for predefined and dynamic grid items alike
  for (const size in gridLayout) {
    gridLayout[size] = keyBy(gridLayout[size], 'i');
  }
  if (global.localStorage) {
    global.localStorage.setItem(
      `${localStorageKey}-${tabKey}`,
      JSON.stringify(gridLayout),
    );
  }
};

export const onGridLayoutChange = (tabKey, allLayouts) => {
//   console.log(`onGridLayoutChange`, { tabKey, allLayouts: JSON.parse(JSON.stringify(allLayouts)) });
  saveLayout(tabKey, allLayouts);
};

export const rowHeight = 10;
export const defaultLayout = {
  [districtTabKey]: {
    lg: {
      "sem_analytics_applications_with_reputations": {
        "w": 8,
        "h": 13,
        "x": 0,
        "y": 0,
        "i": "sem_analytics_applications_with_reputations"
      },
      "sem_analytics_application_history_bespoke_ui": {
        "w": 7,
        "h": 6,
        "x": 17,
        "y": 0,
        "i": "sem_analytics_application_history_bespoke_ui"
      },
      "sem_analytics_application_history": {
        "w": 24,
        "h": 9,
        "x": 0,
        "y": 113,
        "i": "sem_analytics_application_history"
      },
      "sem_analytics_applications_by_program": {
        "w": 9,
        "h": 13,
        "x": 8,
        "y": 0,
        "i": "sem_analytics_applications_by_program"
      },
      "sem_analytics_applications_by_program_last_year": {
        "w": 24,
        "h": 10,
        "x": 0,
        "y": 132,
        "i": "sem_analytics_applications_by_program_last_year"
      },
      "sem_analytics_applications_by_program_year_over_year": {
        "w": 24,
        "h": 10,
        "x": 0,
        "y": 122,
        "i": "sem_analytics_applications_by_program_year_over_year"
      },
      "sem_analytics_applications_by_program_year_over_year_bespoke_ui": {
        "w": 7,
        "h": 7,
        "x": 17,
        "y": 6,
        "i": "sem_analytics_applications_by_program_year_over_year_bespoke_ui"
      },
    },
  },
  [schoolTabKey]: {
    lg: {
      "sem_analytics_applications_with_reputations_school": {
        "w": 8,
        "h": 13,
        "x": 0,
        "y": 0,
        "i": "sem_analytics_applications_with_reputations_school",
      },
      "sem_analytics_application_history_bespoke_ui": {
        "w": 7,
        "h": 6,
        "x": 17,
        "y": 36,
        "i": "sem_analytics_application_history_bespoke_ui",
      },
      "sem_analytics_application_history": {
        "w": 24,
        "h": 9,
        "x": 0,
        "y": 49,
        "i": "sem_analytics_application_history",
      },
      "sem_analytics_applications_by_program": {
        "w": 9,
        "h": 13,
        "x": 8,
        "y": 36,
        "i": "sem_analytics_applications_by_program",
      },
      "sem_analytics_applications_by_program_last_year": {
        "w": 24,
        "h": 10,
        "x": 0,
        "y": 68,
        "i": "sem_analytics_applications_by_program_last_year",
      },
      "sem_analytics_applications_by_program_year_over_year": {
        "w": 24,
        "h": 10,
        "x": 0,
        "y": 58,
        "i": "sem_analytics_applications_by_program_year_over_year",
      },
      "sem_analytics_applications_by_program_year_over_year_bespoke_ui": {
        "w": 7,
        "h": 7,
        "x": 17,
        "y": 42,
        "i": "sem_analytics_applications_by_program_year_over_year_bespoke_ui",
      },
      "sem_analytics_applications_by_zone_school": {
        "w": 24,
        "h": 12,
        "x": 0,
        "y": 24,
        "i": "sem_analytics_applications_by_zone_school",
      },
      "sem_analytics_applications_by_school_filtered_by_zone_school": {
        "w": 16,
        "h": 13,
        "x": 8,
        "y": 0,
        "i": "sem_analytics_applications_by_school_filtered_by_zone_school",
      },
      "sem_analytics_applications_by_program_type_filtered_by_zone_school": {
        "w": 8,
        "h": 13,
        "x": 0,
        "y": 36,
        "i": "sem_analytics_applications_by_program_type_filtered_by_zone_school",
      },
      "sem_analytics_applications_by_program_filtered_by_zone_school": {
        "w": 24,
        "h": 11,
        "x": 0,
        "y": 13,
        "i": "sem_analytics_applications_by_program_filtered_by_zone_school",
      }
    }
  },
  [programTypeTabKey]: {
    lg: {
      "sem_analytics_applications_by_program_by_program": {
        "w": 24,
        "h": 10,
        "x": 0,
        "y": 0,
        "i": "sem_analytics_applications_by_program_by_program",
      },
      "sem_analytics_applications_by_zone_school": {
        "w": 24,
        "h": 10,
        "x": 0,
        "y": 10,
        "i": "sem_analytics_applications_by_zone_school",
      }
    }
  },
  [finderTabKey]: {
    lg: {
      "sem_analytics_google_analytics": {
        "w": 16,
        "h": 9,
        "x": 0,
        "y": 12,
        "i": "sem_analytics_google_analytics",
      },
      "sem_analytics_google_analytics_referrer": {
        "w": 16,
        "h": 9,
        "x": 8,
        "y": 21,
        "i": "sem_analytics_google_analytics_referrer",
      },
      "sem_analytics_google_analytics_referrer_simple": {
        "w": 8,
        "h": 9,
        "x": 0,
        "y": 21,
        "i": "sem_analytics_google_analytics_referrer_simple",
      },
      "sem_analytics_facebook": {
        "w": 8,
        "h": 9,
        "x": 16,
        "y": 12,
        "i": "sem_analytics_facebook",
      },
      "sem_analytics_finder": {
        "w": 8,
        "h": 12,
        "x": 0,
        "y": 0,
        "i": "sem_analytics_finder",
      },
      "sem_analytics_finder_by_location": {
        "w": 8,
        "h": 12,
        "x": 16,
        "y": 0,
        "i": "sem_analytics_finder_by_location",
      },
      "sem_analytics_finder_by_distance": {
        "w": 8,
        "h": 12,
        "x": 8,
        "y": 0,
        "i": "sem_analytics_finder_by_distance",
      },
      "sem_analytics_re_enrollment_history_bespoke_ui": {
        "w": 11,
        "h": 10,
        "x": 0,
        "y": 59,
        "i": "sem_analytics_re_enrollment_history_bespoke_ui",
      },
      "sem_analytics_re_enrollment_history": {
        "w": 13,
        "h": 10,
        "x": 11,
        "y": 59,
        "i": "sem_analytics_re_enrollment_history",
      },
      "sem_analytics_total_enrollment_history_bespoke_ui": {
        "w": 6,
        "h": 10,
        "x": 18,
        "y": 30,
        "i": "sem_analytics_total_enrollment_history_bespoke_ui",
      },
      "sem_analytics_total_enrollment_history": {
        "w": 18,
        "h": 10,
        "x": 0,
        "y": 30,
        "i": "sem_analytics_total_enrollment_history",
      },
      "sem_analytics_applications_by_zone_ms_XXX": {
        "w": 24,
        "h": 9,
        "x": 0,
        "y": 40,
        "i": "sem_analytics_applications_by_zone_ms_XXX",
      },
      "sem_analytics_applications_by_program_XXX": {
        "w": 18,
        "h": 10,
        "x": 0,
        "y": 49,
        "i": "sem_analytics_applications_by_program_XXX",
      },
      "sem_analytics_application_history_bespoke_ui_XXX": {
        "w": 6,
        "h": 10,
        "x": 18,
        "y": 49,
        "i": "sem_analytics_application_history_bespoke_ui_XXX",
      }
    }
  },
};

export const defaultDataGrid = { x: 0, y: rowHeight, w: 24, h: 10 };

export const getDataGridForKey = (layout, componentKey, defaultPosition = null, size = 'lg') => {
    return extend({i: componentKey}, layout?.[size]?.[componentKey] || defaultPosition || defaultDataGrid);
};

export default layout;
