/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import {
  REGEX,
  APPLICATION_LOTTERY_RANKING_LIST,
  APPLICATION_LOTTERY_RANKING_STATUSES,
  SETTINGS_TABS,
} from 'common/constants';
import {
  isEmpty, filter, has, transform, isArray, snakeCase, isObject as checkIsObject,
} from 'lodash';
import React from 'react';
import { isInRange, toDateTime } from 'common/helpers/dateUtils';

// Use when merging an array of objects into a single one. Use this
// helper instead of a spread approach due to performance reasons
export const arrayToObject = (array, key, value = null) => {
  if (!array || !array.length) return {};

  return array.reduce((acc, curr) => {
    acc[curr[key]] = value ? curr[value] : curr;
    return acc;
  }, {});
};

// Remove all chars that are not numbers if the username is a phone
export const cleanUsername = (originalUsername) => {
  if (!originalUsername.match(REGEX.emailValidation)) {
    const phoneNumber = originalUsername.match(/\d+/g);
    return phoneNumber ? phoneNumber.join('') : originalUsername;
  }

  return originalUsername;
};

// Check if an error catched during an API request is from the API
// or something else and return the correct message
export const parseApiError = (error) => {
  const apiError = error.response;

  return apiError ? apiError.data.errors : error.message;
};

// Converts a URL into a title, removes the dashes and also changes
// the name and decodes the URI into a readable name
export const convertToTitleize = (name) => {
  let parts = decodeURIComponent(name).split(/[-|_\s+]/g);
  parts = parts.map(
    (element) => element.charAt(0).toUpperCase() + element.slice(1),
  );
  return parts.join(' ');
};

// Changes the url to the last path before the /
// For instance test/page1/page2
// Will return test/page1
export const getPreviousRoute = (pathname, pagesBack = 1) => {
  let paths = pathname.split('/');
  paths = paths.slice(0, paths.length - (pagesBack));
  return paths.join('/');
};

// return Y or N from a boolean value
export const adminBoolToY = (boolValue) => (boolValue ? 'Y' : 'N');

export const numberToBool = (numValue) => !!numValue;

export const stringToBool = (stringValue) => (stringValue ? /^\s*(true|yes|1|t|y)\s*$/i.test(stringValue) : false);

// return reordered array
export const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Check if the values of a given array of objects or / and arrays
// are not empty
export const isDataPresent = (data) => data.every((dataSet) => {
  const isObject = dataSet.constructor === Object;
  return isObject ? Object.keys(dataSet).length > 0 : dataSet.length > 0;
});

/**
 * Method to determine if the values of an object are empty.
 * NOTE: only takes cosider plain object, for nested object custom logic is needed.
 * @param object
 */
export const emptyProperties = (object) => !Object.values(object).some((x) => !isEmpty(x));

export const toCamelCase = (e) => e && e.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

export const toSnakeCase = (e) => e
  && e
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');

export const toCamelCaseKeys = (obj) => {
  const newObject = {};
  Object.entries(obj).forEach(([key, value]) => {
    newObject[toCamelCase(key)] = value;
  });
  return newObject;
};

export const isAdminSite = () => {
  const { location } = window;
  const adminPath = /(^\/manage(\/|$))/;
  return adminPath.test(location.pathname);
};

export const toSnakeCaseKeys = (obj) => {
  const newObject = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (isEmpty(value) || value.constructor !== Object) {
      newObject[toSnakeCase(key)] = value;
    } else {
      const newValue = toSnakeCaseKeys(value);
      newObject[toSnakeCase(key)] = newValue;
    }
  });
  return newObject;
};

export const toSnakeCaseAllKeys = (obj) => transform(obj, (acc, value, key, target) => {
  const snakeKey = isArray(target) ? key : snakeCase(key);

  acc[snakeKey] = checkIsObject(value) ? toSnakeCaseAllKeys(value) : value;
});

export const pick = (obj, keys) => keys
  .map((k) => (k in obj ? { [k]: obj[k] } : {}))
  .reduce((res, o) => Object.assign(res, o), {});

/**
 * Method the get a nested value. sample:
 * const obj = {a: {b: c:{d:1}}};
 * getValue(obj, 'a.b.c.d');
 * @param Object obj javascript object
 * @param string path nested attribute path
 * @param  defaultValue default value in case path not exist.
 */
export const getValue = (obj, path, defaultValue = null) => {
  const value = path.split('.').reduce((xs, x) => ((xs) ? xs[x] : null), obj);
  return value === null || value === undefined ? defaultValue : value;
};

/**
 * method to set nested value. sample:
 * obj: a={};
 * setValue(a, 'a.b.c.d', 1)
 * expected object:
 * {a: {b: c:{d:1}}}
 * @param Object obj
 * @param String/Array path nested attribute path
 * @param {*} value
 */
export const setValue = (obj, path, value) => {
  const properties = Array.isArray(path) ? path : path.split('.');
  const data = { ...obj };
  const [property, ...rest] = properties;
  if (properties.length > 1) {
    if (!data[property]) {
      data[property] = {};
    }
    data[property] = setValue(data[property], rest, value);
    return data;
  }
  data[property] = value;
  return data;
};
/**
 * delete nested attribute from object
 * @param Object obj
 * @param String/Array path nested attribute path
 */
export const deleteAttribute = (obj, path) => {
  const properties = Array.isArray(path) ? [...path] : path.split('.');
  const data = { ...obj };
  const [attribute, ...rest] = properties;
  if (properties.length > 1) {
    if (data[attribute]) {
      data[attribute] = deleteAttribute(data[attribute], rest);
      return data;
    }
  }
  delete data[attribute];
  return data;
};

/**
 * method merge object and avoiding the overriding nested object
 * @param Object target
 * @param Object source
 */
export const mergeObject = (target, source) => {
  Object.entries(source).forEach(([key, value]) => {
    if (value instanceof Object) {
      Object.assign(value, mergeObject(target[key] ?? {}, value));
    }
  });
  Object.assign(target || {}, source);
  return target;
};

/**
 * Method to remove the all null attributes and null items in array
 * @param Array/Object obj
 */
export const cleanObject = (obj) => {
  const object = Array.isArray(obj) ? [...obj] : { ...obj };
  Object.entries(object).forEach(([k, v]) => {
    let value = v;
    if (v instanceof Object) {
      value = cleanObject(v);
      object[k] = value;
    }

    if ((value instanceof Object && Object.keys(value).length === 0) || value === null || value === undefined || value.length === 0) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else if (!(v instanceof Date)) {
        delete object[k];
      }
    }
  });
  return object;
};

export const filterObject = (object, filterCb) => Object.keys(object)
  .filter((key) => filterCb(object[key], key))
  .reduce(
    (filtered, key) => ({
      ...filtered,
      [key]: object[key],
    }),
    {},
  );

export const leavingPageWarning = (add) => {
  const warning = add ? () => 'warning' : null;
  window.onbeforeunload = warning;
};

export const sort = (array, sortAttribute) => {
  if (array) {
    return Object.values(array).sort((a, b) => (a[sortAttribute] > b[sortAttribute] ? 1 : -1));
  }
  return array;
};

export const keyDownEnter = (e, callback) => {
  if (e.which === 13) {
    callback();
  }
};

export const limitText = (text, limit = 50) => {
  const newText = text || '';

  return newText.length <= limit ? newText : `${text.slice(0, limit - 3)}...`;
};

export const deepCopy = (inObject) => {
  let outObject;

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject;
  }

  if (Array.isArray(inObject)) {
    outObject = [];
    inObject.forEach((inEntry, i) => { outObject[i] = deepCopy(inEntry); });
  } else {
    outObject = {};
    Object.entries(inObject).forEach(([key, value]) => { outObject[key] = deepCopy(value); });
  }

  return outObject;
};

export const sortAsc = (array, sortAttribute) => {
  if (array) {
    return Object.values(array).sort((a, b) => -b[sortAttribute].localeCompare(a[sortAttribute]));
  }
  return array;
};

export const diffValues = (arr1, arr2) => (
  Object.keys(arr1).reduce((diff, key) => {
    if (arr2[key] === arr1[key]) return diff;
    return {
      ...diff,
      [key]: arr1[key],
    };
  }, {})
);

/**
 *
 * @param {string} key : either programId, categoryId, levelId
 * @param {array} entities: array of programs, lotteryRuns, etc
 * @param {array} ids: array of ids to match
 */
export const filterEntitiesByIds = (idKey = '', entities = {}, ids = []) => entities.filter((entity) => ids.includes(entity[idKey]));

/**
 * Sorts the list of grade labels [1,2,3,4,k]
 * by its num value in the global grades
 * gradesNumAssigment parameter is an array of key values key represents the label and value the num
 * example [k => -1, 1 = 0, 2 = 3].
 */
export const sortGradeLabels = (gradesNumAssigment, grades) => {
  grades.sort((a, b) => {
    const aValue = gradesNumAssigment[a];
    const bValue = gradesNumAssigment[b];
    if (aValue === bValue) {
      return 0;
    }

    return (aValue < bValue) ? -1 : 1;
  });

  return grades;
};

export const buildCheckboxObject = (listValues) => {
  const splittedList = listValues ? listValues.split(',') : [];
  const objectValues = {};
  splittedList.forEach((element) => {
    objectValues[element] = true;
  });
  return objectValues;
};

/**
 *
 * @param {obj} formValues
 * @param {obj} options
 */
export function validateAccountInfoForm(formValues, formErrors, validationFunction, options) {
  const validateField = validationFunction;
  const { passwordValidation, isSignup, inAdminSite = false } = options;
  let validations = {
    firstname: formValues.firstname,
    lastname: formValues.lastname,

  };
  if (inAdminSite) {
    validations = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
    };
  }
  if (isSignup) {
    validations = {
      ...validations,
      password: formValues.password,
      passwordConfirmationPresence: formValues.passwordConfirmation,
      emailConfirmation: [formValues.email, formValues.emailConfirmation],
      smsOrEmailCommunicationPresence: [
        formValues.smsNotifications,
        formValues.emailNotifications,
      ],
      phoneOrEmailAndEmailConfimationPresence: [
        formValues.phone,
        formValues.email,
        formValues.emailConfirmation,
      ],
    };
  } else {
    validations = {
      ...validations,
      phoneOrEmailPresence: [
        formValues.phone,
        formValues.email,
      ],
    };
  }
  if (passwordValidation) {
    validations = {
      ...validations,
      password: formValues.password,
      passwordConfirmationPresence: formValues.passwordConfirmation,
      currentPassword: formValues.currentPassword,
    };
  }
  const results = {};
  Object.keys(validations).forEach((key) => {
    const result = validateField(key, validations[key]);
    if (result) {
      results[key] = result;
    }
  });
  const hasFieldErrors = Object.keys(formErrors).filter((k) => !validations[k]).length > 0;
  const formHasErrors = Object.keys(results).length > 0 || hasFieldErrors;
  return [formHasErrors, results];
}

/**
 *
 * @param {obj} student
 */

export function studentName(student) {
  if (!student || (!student.fname && !student.name)) return 'student';
  const { fname, lname } = student;
  return `${fname} ${lname}`;
}

export function userFullName(user) {
  if (!user || (!user.first_name && !user.last_name)) return '';
  return `${user.first_name} ${user.last_name}`;
}

/**
 *
 * @param {obj} student
 */
export function studentID(student) {
  if (!student) return '';
  return student.student_district_id ? student.student_district_id : `SM-${student.id}`;
}

export function getRegistrationStudent(applications, enrollmentEnabled) {
  if (!enrollmentEnabled) return [];
  const appsOffered = filter(applications, ({ lottery_ranking: lotteryRanking }) => (
    lotteryRanking
    && lotteryRanking.lottery_list === APPLICATION_LOTTERY_RANKING_LIST.offered
    && lotteryRanking.accepted === APPLICATION_LOTTERY_RANKING_STATUSES.accepted));
  const appsForRegister = [];
  appsOffered.forEach((application) => {
    let isOpen = false;
    const { school } = application.program;
    const schoolAnnual = school.annual;
    if (schoolAnnual && schoolAnnual.enroll_open_dates === 'default') {
      if (schoolAnnual.level && schoolAnnual.level.annual && schoolAnnual.level.annual.enroll_open_date && schoolAnnual.level.annual.enroll_close_date) {
        isOpen = isInRange(new Date(), toDateTime(schoolAnnual.level.annual.enroll_open_date), toDateTime(schoolAnnual.level.annual.enroll_close_date));
      }
    } else if (schoolAnnual && schoolAnnual.enroll_open_date && schoolAnnual.enroll_close_date) {
      isOpen = isInRange(new Date(), toDateTime(schoolAnnual.enroll_open_date), toDateTime(schoolAnnual.enroll_close_date));
    }

    if (isOpen && schoolAnnual.enroll_form_process_id) {
      appsForRegister.push(application);
    }
  });

  return appsForRegister;
}

/**
  * Calculate the center/average of multiple GeoLocation coordinates
  * Expects an array of objects with .lat and .lng properties
  */
export const averageGeolocation = (coords) => {
  let coord = coords && coords.length ? coords[0] : { lat: 35.8434428, lng: -78.9252351 };
  let nw = { lat: coord.lat, lng: coord.lng };
  let se = { lat: coord.lat, lng: coord.lng };

  coords.forEach((coord) => {
    const { lat, lng } = coord;
    if (coord.lat > nw.lat) {
      nw = { lat, lng: nw.lng };
    }
    if (coord.lng < nw.lng) {
      nw = { lat: nw.lat, lng };
    }

    if (coord.lat < se.lat) {
      se = { lat, lng: se.lng };
    }
    if (coord.lng > se.lng) {
      se = { lat: se.lat, lng };
    }
  });
  if (coords.length === 1) {
    nw.lat += 0.00001;
    nw.lng -= 0.00001;
  }
  return { nw, se };
};

/**
 * Takes an string url and returns the base protocol + host only
 */
export const getBaseUrl = (url) => {
  const pathArray = url.split('/');
  const protocol = pathArray[0];
  const host = pathArray[2];
  return `${protocol}//${host}`;
};

export const getShortYearPeriod = (currentYear) => {
  if (currentYear) {
    const year = parseInt(currentYear.slice(-2), 10);
    return `${year}/${year + 1}`;
  }
  return '';
};

export const orderApplicationsByProcessName = (applications) => {
  if (applications.length > 0 && applications[0].program) return applications.sort((a1, a2) => a1.program.title.localeCompare(a2.program.title));
  return applications;
};

export const updateTranslationsValuesForTranslationEditor = (translationValues, editorType, { language, type, newValue }) => {
  const idx = editorType === 'multiple' ? translationValues[language][0].value.findIndex((i) => i.type === type)
    : translationValues[language].findIndex((i) => i.type === type);

  const updatedTranslations = { ...translationValues };
  if (editorType === 'multiple') {
    updatedTranslations[language][0].value[idx].value = newValue;
  } else {
    updatedTranslations[language][idx].value = newValue;
  }
  return updatedTranslations;
};

export const buildEmptyTranslationContent = (languages, contentTypes) => {
  const translations = {};
  if (typeof (contentTypes[0]) === 'string') {
    languages.forEach((lang) => {
      translations[lang] = contentTypes.map((key) => ({ type: key, value: '' }));
    });
  }
  return translations;
};

export const buildEmptyObjectTranslationContent = (languages, contentTypes) => {
  const translations = {};
  if (typeof (contentTypes[0]) === 'string') {
    languages.forEach((lang) => {
      translations[lang] = contentTypes.reduce((mem, key) => ({ ...mem, ...{ [key]: '' } }), {});
    });
  }
  return translations;
};

export const formatTranslations = (translations, languages, fieldNames) => {
  const formattedTranslations = {};
  Object.entries(translations).forEach(([lang, notificationTranslations]) => {
    const translationObject = [];
    notificationTranslations.forEach((translation) => {
      translationObject.push({ type: translation.field_name, value: translation.html });
    });
    formattedTranslations[lang] = translationObject;
  });

  const emptyLanguages = languages.map((lang) => (!formattedTranslations[lang] ? lang : '')).filter((lang) => lang);
  const emptyTranslations = buildEmptyTranslationContent(emptyLanguages, fieldNames);

  return { ...formattedTranslations, ...emptyTranslations };
};

export const formatTranslationForSave = (translations) => {
  const newTranslations = {};
  Object.entries(translations).forEach(([lang, translationInfo]) => {
    const newTranslation = translationInfo.map(({ type, value }) => ({
      field_name: type,
      html: value,
    }));

    newTranslations[lang] = newTranslation;
  });

  return newTranslations;
};

export const formatFormRelatedTranslationsForSave = (translations) => {
  const newTranslations = [];
  Object.entries(translations).map(([lang, translationInfo]) => {
    const newTranslation = translationInfo.reduce((mem, { type, value }) => ({
      ...mem,
      ...{
        field_name: type,
        html: value,
        lang,
      },
    }), {});

    return newTranslations.push(newTranslation);
  });

  return newTranslations;
};

export const buildTranslationsLanguageArray = (translations, formatArray = true, languages = [], customFieldNames = []) => {
  const formattedTranslations = {};
  if (!isEmpty(languages)) {
    languages.forEach((lang) => { (formattedTranslations[lang] = formatArray ? [] : {}); });
  }
  if (!isEmpty(translations)) {
    translations.forEach((translation) => {
      if (customFieldNames.indexOf(translation.field_name) >= 0) {
        if (!formattedTranslations[translation.lang]) {
          formattedTranslations[translation.lang] = formatArray ? [] : {};
        }
        if (formatArray) {
          if (customFieldNames.indexOf(translation.field_name) >= 0) {
            formattedTranslations[translation.lang].push({ type: translation.field_name, value: translation.html });
          }
        } else if (customFieldNames.indexOf(translation.field_name) >= 0) {
          formattedTranslations[translation.lang][translation.field_name] = translation.html;
        }
      }
    });
  }
  return formattedTranslations;
};

export const formatFormRelatedTranslations = (translations, languages, customFieldNames) => {
  const formattedTranslations = buildTranslationsLanguageArray(translations, true, languages, customFieldNames);
  const emptyLanguages = languages.map((lang) => (isEmpty(formattedTranslations[lang]) ? lang : '')).filter((lang) => lang);
  const emptyTranslations = buildEmptyTranslationContent(emptyLanguages, customFieldNames);
  return { ...formattedTranslations, ...emptyTranslations };
};

export const formatTranslationsCustomDataSource = (translations, languages, dataSourcesNames) => {
  const formattedTranslations = buildTranslationsLanguageArray(translations, false, languages, dataSourcesNames);
  const emptyLanguages = languages.map((lang) => (isEmpty(formattedTranslations[lang]) ? lang : '')).filter((lang) => lang);
  const emptyTranslations = buildEmptyObjectTranslationContent(emptyLanguages, dataSourcesNames);
  return { ...formattedTranslations, ...emptyTranslations };
};

export const buildCsvDataFromReport = (report) => {
  const { columns = [], data = [] } = report;
  const dataCsv = [];
  const columnNames = columns.map((column) => `"${column.header}"`);
  dataCsv.push(columnNames.join(','));
  data.forEach((d) => {
    const dTemp = [];
    columns.forEach((column) => {
      let value = d[`${column.table_name}.${column.field_name}`];
      if (value === null || value === undefined) {
        value = '';
      }
      dTemp.push(`"${value}"`);
    });
    dataCsv.push(dTemp.join(','));
  });
  return dataCsv.join('\n');
};

export const downloadCsv = (data, filename = 'nextgen.csv') => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

/**
 * 
 * @param {*} x 
 * @param {*} y 
 * @param {*} polygon 
 * @returns 
 */
export const isPointInPolygon = (x, y, polygon) => {
  let i = 0
  let ii = 0
  let k = 0
  let f = 0
  let u1 = 0
  let v1 = 0
  let u2 = 0
  let v2 = 0
  let currentP = null
  let nextP = null

  // const x = p[0]
  // const y = p[1]

  const numContours = polygon.length
  for (i; i < numContours; i++) {
      ii = 0
      const contourLen = polygon[i].length - 1
      const contour = polygon[i]

      currentP = contour[0]
      if (currentP.lat !== contour[contourLen].lat &&
          currentP.lng !== contour[contourLen].lng) {
          throw new Error('First and last coordinates in a ring must be the same')
      }

      u1 = currentP.lat - x
      v1 = currentP.lng - y

      for (ii; ii < contourLen; ii++) {
          nextP = contour[ii + 1]

          v2 = nextP.lng - y

          if ((v1 < 0 && v2 < 0) || (v1 > 0 && v2 > 0)) {
              currentP = nextP
              v1 = v2
              u1 = currentP.lat - x
              continue
          }

          u2 = nextP.lat - x

          if (v2 > 0 && v1 <= 0) {
              f = (u1 * v2) - (u2 * v1)
              if (f > 0) k = k + 1
              else if (f === 0) return 0
          } else if (v1 > 0 && v2 <= 0) {
              f = (u1 * v2) - (u2 * v1)
              if (f < 0) k = k + 1
              else if (f === 0) return 0
          } else if (v2 === 0 && v1 < 0) {
              f = (u1 * v2) - (u2 * v1)
              if (f === 0) return 0
          } else if (v1 === 0 && v2 < 0) {
              f = u1 * v2 - u2 * v1
              if (f === 0) return 0
          } else if (v1 === 0 && v2 === 0) {
              if (u2 <= 0 && u1 >= 0) {
                  return 0
              } else if (u1 <= 0 && u2 >= 0) {
                  return 0
              }
          }
          currentP = nextP
          v1 = v2
          u1 = u2
      }
  }

  if (k % 2 === 0) return false
  return true
};

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
}

// copied from finder
export const getDistanceFromLatLonInMiles = (lat1, lon1, lat2, lon2) => {
  const R = 6371,
      dLat = deg2rad(lat2 - lat1),
      dLon = deg2rad(lon2 - lon1),
      a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(lat1)) *
          Math.cos(deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2),
      c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)),
      d = (R * c) / 1.609344; // Distance in miles
  return d;
};

/**
 * Distance in miles between two lat/lng points.
 * 
 * @param {*} point1
 * @param {*} point2
 * @returns 
 */
 export function getDistanceInMiles(point1, point2) {
  const lat1 = parseFloat(point1.lat);
  const lon1 = parseFloat(point1.lng);
  const lat2 = parseFloat(point2.lat);
  const lon2 = parseFloat(point2.lng);

  return getDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2);
};
