import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.background.default,
    padding: theme.spacing(1, 1),
    fontWeight: '700',
    fontSize: '0.8125rem',
  },
  body: {
    padding: theme.spacing(0, 1),
    border: 0,
    fontWeight: '500',
  },
}))(TableCell);

export default StyledTableCell;
