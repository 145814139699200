import { isObject } from 'lodash';

export function isMappingCompleted(
  fieldMappings,
  mapping,
  category,
  categoryConfiguration
) {
  let status = '';
  let totalFieldsCompleted = 0;
  let totalFieldsStarted = 0;
  let mappingByCategory = mapping[category];

  // when is user defined fields,
  // total is calculated only from those that
  // have been actually mapped and are not deleted
  let isUserDefined = isCategoryUserDefinedFields(
    category,
    categoryConfiguration
  );
  if (isUserDefined) {
    mappingByCategory = fieldMappingsForUserDefinedCategory(
      category,
      fieldMappings
    );
    Object.keys(mappingByCategory).forEach((key) => {
      if (mappingByCategory[key].deleted) {
        delete mappingByCategory[key];
      }
    });
  }
  const totalFieldMaps = Object.keys(mappingByCategory).length;

  if (Object.keys(fieldMappings).length > 0) {
    Object.keys(fieldMappings).forEach((key) => {
      const isOnMappingByCategory = mappingByCategory[key];
      if (isOnMappingByCategory !== undefined) {
        totalFieldsStarted++;
        if (isFieldMappingCompleted(fieldMappings[key])) {
          totalFieldsCompleted++;
        }
      }
    });
  }
  if (totalFieldsStarted === 0) {
    status = 'not_started';
    return {
      status,
    };
  }
  status =
    totalFieldsCompleted === totalFieldMaps ? 'completed' : 'in_progress';
  return {
    status,
    totalFieldsCompleted,
    totalFieldMaps,
  };
}

export function isFieldMappingCompleted(fieldMapping) {
  const { type, mapping } = fieldMapping;
  return (
    type === 'not_mapped' ||
    (type === 'direct' &&
      mapping &&
      mapping.args &&
      mapping.args[0] &&
      mapping.method &&
      mapping.method === 'direct') ||
    (type === 'mapped_value' &&
      mapping &&
      mapping.method == 'mapped_value' &&
      mapping.args[0]) ||
    (type === 'transformation' && mapping)
  );
}

export function getUserDefinedFieldsRegex(categoryKey) {
  return new RegExp(`user_defined\\.${categoryKey}\\.(\\d+)\\.`);
}

export function fieldMappingsForUserDefinedCategory(category, fieldMappings) {
  var userDefinedFieldsForCategory = {};

  Object.keys(fieldMappings)
    .sort()
    .forEach(function (field) {
      if (field.match(getUserDefinedFieldsRegex(category))) {
        userDefinedFieldsForCategory[field] = fieldMappings[field];
      }
    });

  return userDefinedFieldsForCategory;
}

export function isCategoryUserDefinedFields(
  categoryKey,
  categoryConfiguration
) {
  return (
    isCategoryUserDefined(categoryKey) || categoryConfiguration.is_user_defined
  );
}

export function getCustomCategoryRegex() {
  return new RegExp(`custom_category\\-(\\d+)`);
}

export function isCategoryUserDefined(categoryKey) {
  return categoryKey.match(getCustomCategoryRegex());
}

export function getPropertyFromObject(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1');
  s = s.replace(/^\./, '');
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (isObject(o) && k in o) {
      o = o[k];
    } else {
      if (isObject(o) && Object.keys(o).length > 0) {
        var keys = Object.keys(o);
        o = o[keys[0]];
        return getPropertyFromObject(o, s);
      } else {
        return;
      }
    }
  }

  return o;
}

export function* paginate(
  {
    payload,
    serviceCallback,
    sliceSuccessCallback,
    sliceFailedCallback,
    call,
    put,
  },
  shallPaginate = true
) {
  try {
    const { data } = yield call(serviceCallback, payload);
    let didFinished = true;

    if (
      payload.filters &&
      payload.filters.hasOwnProperty('limit') &&
      payload.filters.hasOwnProperty('offset')
    ) {
      const currentCount = payload.filters.offset + data.total_current_page;
      if (currentCount < data.total) {
        didFinished = false;
      }
    }

    yield put(
      sliceSuccessCallback({
        data,
        didFinished,
      })
    );
    // if limit and offset are set then we do know pagination is required
    if (!didFinished && shallPaginate) {
      const currentCount = payload.filters.offset + data.total_current_page;
      const filters = {
        ...payload.filters,
        limit: payload.filters.limit,
        offset: currentCount,
      };
      yield paginate(
        {
          payload: { ...payload, filters },
          serviceCallback,
          sliceSuccessCallback,
          sliceFailedCallback,
          call,
          put,
        },
        shallPaginate
      );
    }
  } catch (e) {
    yield put(sliceFailedCallback({ error: e }));
  }
}

export function isJsonString(string) {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
}
