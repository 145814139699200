import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { Tooltip, StyledToggleButton } from 'components';
import useStyle from './style';
import { isJsonString } from '../../../helpers';

const StatusCell = ({ status, sync_results, statuses }) => {
  const styles = useStyle();

  let title;
  if (!sync_results) {
    title = '';
  } else if (isJsonString(JSON.stringify(sync_results))) {
    let json = JSON.parse(JSON.stringify(sync_results));
    let error = json['error'] ?? {};
    title = JSON.stringify(error);
  }

  return (
    <Tooltip title={title}>
      <StyledToggleButton className={`${statuses[status]} ${styles.cell}`}>
        <Box
          borderRadius={20}
          border={2.5}
          p={0.25}
          pl={1}
          pr={1}
          minWidth={110}
          className={`${styles.boxBackground}`}
        >
          <Typography variant="subtitle1" color="inherit">
            {status.replace('-', ' ').toUpperCase()}
          </Typography>
        </Box>
      </StyledToggleButton>
    </Tooltip>
  );
};

StatusCell.propTypes = {
  status: PropTypes.string.isRequired,
  sync_results: PropTypes.object,
  statuses: PropTypes.object,
};

StatusCell.defaultProps = {
  statuses: {
    'in-progress': 'primary',
    'action-required': 'neutral',
    failed: 'negative',
    pending: 'neutral',
    succeeded: 'positive',
  },
};

export default StatusCell;
