import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import HeaderToggleButton from './HeaderToggleButton';

const HeaderToggle = ({ options, onChange }) => {
  const [selection, setSelection] = React.useState(null);

  const handleSelection = (newSelection) => {
    setSelection(newSelection);
    onChange(newSelection);
  };

  return (
    <Box
      border={1}
      borderBottom={0}
      borderRadius={3}
      borderColor="common.borders"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {options.map((option, index) => {
          return (
            <HeaderToggleButton
              key={`${option.value}`}
              isFinal={index + 1 == options.length}
              selected={selection == option.value}
              value={option.value}
              title={option.title}
              subtitle={option.subtitle}
              type={option.type}
              onClick={handleSelection}
              disabled
            />
          );
        })}
      </Grid>
    </Box>
  );
};

HeaderToggle.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

HeaderToggle.defaultProps = {};

export default HeaderToggle;
