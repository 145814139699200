import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  full: {
    // FIXME: this is specifically for full-page loader accounting for header height
    height: 'calc(100vh - 250px)',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  medium: {
    height: '100%',
    width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  small: {
    height: '100%',
    width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  fullAnimation: {
    width: "300px"
  },
  mediumAnimation: {
    width: "100px"
  },
  smallAnimation: {
    width: "50px"
  },
  loadingText: {
    color: '#052975',
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '10px'
  }
});
