import React from 'react';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';

function ErrorHandler() {
  const [serverError, setServerError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const isServerError = error.response.status >= 500;
          if (isServerError) {
            enqueueSnackbar('Something went wrong!', { variant: 'error' });
          }

          const shallRedirectUnderConnectionSetup =
            window.location.href.match(new RegExp(/\/connections\/\d+/)) &&
            error.response?.data?.type === 'ConnectionSetupException' &&
            !window.location.href.match(
              new RegExp(/\/connections\/\d+\/setup/)
            );
          if (shallRedirectUnderConnectionSetup) {
            const regex = new RegExp(/\/connections\/\d+/);
            const match = window.location.href.match(regex)[0];
            Cookies.set('initmessage', {
              message:
                'Review your connection setup. ' +
                error.response?.data?.message,
              options: { variant: 'error' },
            });
            window.location.href = match + '/setup';
          }
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }, [axios]);

  const renderServerError = () => {
    return (
      <>
        <Alert severity="error">Something wrong</Alert>
      </>
    );
  };

  return <></>;
}

export default ErrorHandler;
