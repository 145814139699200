import { fork, all } from 'redux-saga/effects';
import { initiateApp } from 'store/app/saga';
import auth from 'store/auth/saga';
import transforms from 'store/transforms/saga';
import adapters from 'store/adapters/saga';
import semAnalytics from 'store/semAnalytics/saga';
import widgets from 'store/widgets/saga';

export default function* rootSaga() {
  yield all([
    fork(initiateApp),
    ...auth,
    // ...transforms,
    // ...adapters,
    ...semAnalytics,
    ...widgets,
  ]);
}
