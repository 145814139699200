import { put, call, takeLeading } from 'redux-saga/effects';

import Adapters from 'services/adapters';

import {
  loadAdaptersByConnection,
  loadAdaptersSucceeded,
  loadAdaptersByConnectionSucceeded,
  loadAdapterFailed,
} from './slice';
import Auth from 'services/auth';

export function* handleLoadAdapters(userData) {
  if (Auth.isLoggedIn()) {
    const { data } = yield call(Adapters.getAdapters);
    yield put(
      loadAdaptersSucceeded({
        adapters: data.data,
        userData,
      })
    );
    return data;
  }
  return {};
}

export function* handleLoadConnectionAdapters(action) {
  try {
    if (Auth.isLoggedIn()) {
      const { data } = yield call(
        Adapters.getConnectionAdapters,
        action.payload.id
      );
      yield put(
        loadAdaptersByConnectionSucceeded({
          adapters: data.data,
        })
      );
    }
  } catch (e) {
    yield put(
      loadAdapterFailed({ errorMessage: e.message, content: e.response.data })
    );
  }
}

const watchers = [
  takeLeading(loadAdaptersByConnection.type, handleLoadConnectionAdapters),
];

export default watchers;
