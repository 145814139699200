import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  isAuthenticated: false,
  loadSessionError: null,
  client: null,
  user: {},
  organization: {},
  school_year: null,
  school_years: [],
  maps_to_show: [],
  settings: {
    has_applications: true,
    has_re_enrollments: true,
    has_programs: true,
    has_finder: true,
    uses_guided_search: true,
    has_google_analytics: true,
    has_facebook: true,
    has_niche: true,
    per_pupil_funding: 10000
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loadSession: (state) => {
      state.isLoading = true;
    },
    loadSessionCompleted: (state, action) => {
      const { payload } = action;
      const { session, settings, maps_to_show } = payload;

      state.isLoading = false;
      state.isAuthenticated = true;
      state.loadSessionError = null;
      state.client = session.client;
      state.user = session.user;
      state.organization = session.organization;
      state.school_year = session.school_year;
      state.school_years = session.school_years;

      state.maps_to_show = maps_to_show;

      state.settings = {
        ...state.settings, // defaults
        ...settings, // client-specific overrides
      };
    },
    loadSessionFailed: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.loadSessionError = action.payload.errorMessage;
    },
    setSchoolYear: (state, action) => {
      state.school_year = action.payload.schoolYear;
    },
  },
});

export const { loadSession, loadSessionCompleted, loadSessionFailed, setSchoolYear } =
  slice.actions;

export const selectIsLoading = (state) => state.auth.isLoading;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectLoadSessionError = (state) => state.auth.loadSessionError;
export const selectClient = (state) => state.auth.client;
export const selectUser = (state) => state.auth.user;
export const selectOrganization = (state) => state.auth.organization;
export const selectSchoolYear = (state) => state.auth.school_year;
export const selectSchoolYears = (state) => state.auth.school_years;
export const selectMapsToShow = (state) => state.auth.maps_to_show;
export const selectHasReEnrollments = (state) => state.auth.settings.has_re_enrollments;
export const selectHasApplications = (state) => state.auth.settings.has_applications;
export const selectHasPrograms = (state) => state.auth.settings.has_programs;
export const selectHasFinder = (state) => state.auth.settings.has_finder;
export const selectUsesGuidedSearch = (state) => state.auth.settings.uses_guided_search;
export const selectHasGoogleAnalytics = (state) => state.auth.settings.has_google_analytics;
export const selectHasFacebook = (state) => state.auth.settings.has_facebook;
export const selectHasNiche = (state) => state.auth.settings.has_niche;
export const selectPerPupilFunding = (state) => state.auth.settings.per_pupil_funding;

export default slice.reducer;
