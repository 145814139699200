import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  searchButton: {
    background: '#fff',
    width: '260px',
  },
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.background.default,
    padding: theme.spacing(1, 1),
    fontWeight: '700',
    fontSize: '0.8125rem',
  },
  body: {
    padding: theme.spacing(0, 1),
    border: 0,
    fontWeight: '500',
  },
  odd: {
    backgroundColor: theme.palette.common.tableRowConstract,
  },
  headerColumnBordersRight: {
    borderTopRightRadius: '4px',
  },
  headerColumnBordersLeft: {
    borderTopLeftRadius: '4px',
  },
}));
