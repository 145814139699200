import { all, put, call } from 'redux-saga/effects';
import { startBoot, completeBoot } from './slice';
import { loadCurrentUserData } from 'store/auth/saga';
import { handleLoadAdapters } from 'store/adapters/saga';
import { handleLoadTransformation } from 'store/transforms/saga';
import { loadApiConfig, unauthorizeInterceptor } from 'configs/api';

function* handleCurrentUserLoad() {
  const userData = yield call(loadCurrentUserData);
  // const adapters = yield call(handleLoadAdapters, userData);
  // yield call(handleLoadTransformation);
  return {
    userData,
    // adapters
  };
}

export function* initiateApp() {
  yield put(startBoot());
  yield call(loadApiConfig);
  yield call(unauthorizeInterceptor);
  const data = yield all([call(handleCurrentUserLoad)]);
  yield put(completeBoot(data[0]));
}
