import { put, call, takeLeading } from 'redux-saga/effects';

import Transforms from 'services/transforms';
import Auth from 'services/auth';

import {
  loadTransformations,
  loadTransformationsSucceeded,
  loadTransformationsFailed,
  transform,
  transformSucceeded,
  transformFailed,
} from './slice';

function* handleApplyTransformations(action) {
  try {
    const { data } = yield call(Transforms.transform, action.payload);
    yield put(
      transformSucceeded({
        data: data.data,
      })
    );
  } catch (e) {
    yield put(transformFailed({ errorMessage: e.message }));
  }
}

export function* handleLoadTransformation() {
  yield put(loadTransformations());
  if (Auth.isLoggedIn()) {
    try {
      const { data } = yield call(Transforms.getTransformations);
      yield put(
        loadTransformationsSucceeded({
          transformations: data.data,
        })
      );
    } catch (e) {
      yield put(loadTransformationsFailed({ errorMessage: e.message }));
    }
  }
}

const watchers = [takeLeading(transform.type, handleApplyTransformations)];

export default watchers;
