import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import StyledToggleButton from './style';
import HeaderToggleButtonTitle from './HeaderToggleButtonTitle';
import HeaderToggleButtonSubtitle from './HeaderToggleButtonSubtitle';
import HeaderToggleIcon from './HeaderToggleIcon';

const HeaderToggleButton = ({
  value,
  title,
  subtitle,
  type,
  onClick,
  selected,
  isFinal,
  disabled,
}) => {
  return (
    <>
      {isFinal && <HeaderToggleIcon />}
      <Grid item xs>
        <StyledToggleButton
          disabled={disabled}
          fullWidth
          className={`${type} ${selected ? 'selected' : ''}`}
          onClick={() => {
            // if button is selected already and user is clicking it again,
            // then disabled the selection
            if (selected) {
              onClick('all');
              return;
            }
            onClick(value);
          }}
        >
          <Box p={1}>
            <Grid
              container
              direction="column"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <HeaderToggleButtonTitle>{title}</HeaderToggleButtonTitle>
              <HeaderToggleButtonSubtitle>
                {subtitle}
              </HeaderToggleButtonSubtitle>
            </Grid>
          </Box>
        </StyledToggleButton>
      </Grid>
    </>
  );
};

HeaderToggleButton.propTypes = {
  isFinal: PropTypes.bool,
  selected: PropTypes.bool,
  value: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.oneOf(['negative', 'positive', 'primary', 'neutral']),
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

HeaderToggleButton.defaultProps = {
  selected: false,
  isFinal: false,
  disabled: false,
};

export default HeaderToggleButton;
