import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';
import { AppBar, Grid, Box } from '@material-ui/core';
import HeaderButton from './HeaderButton';
import HeaderLegend from './HeaderLegend';
import SchoolYearSelector from './SchoolYearSelector';
import { Logo } from 'components';
import { selectOrganization } from 'store/auth/slice';
import { useSelector } from 'react-redux';
import { selectClientName } from 'store/app/slice';
import { useHistory } from 'react-router';
import MyAccountSelector from './MyAccountSelector';

function Header(props) {
  const history = useHistory();
  const styles = useStyles();
  const organization = useSelector(selectOrganization);
  const clientName = useSelector(selectClientName);

  return (
    <React.Fragment>
      <AppBar elevation={0} color="secondary">
        <Box borderBottom={4} className={styles.mainBox}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <Box xs="auto" pl={4}>
                <Logo />
              </Box>
            </Grid>
            {!organization.name ? null :
              <Grid item xs={12} md={8}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ height: '100%' }}
                >
                  <HeaderLegend
                    secondary={organization.name}
                  />
                  <Grid item style={{ marginRight: '10px' }}>
                    <SchoolYearSelector />
                  </Grid>
                  <MyAccountSelector />
                  {/* <HeaderButton
                    href="https://schoolmint.zendesk.com/"
                    target="_blank"
                  >
                    Help
                  </HeaderButton> */}
                </Grid>
              </Grid>
            }
          </Grid>
        </Box>
      </AppBar>
      <Box pt={17} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={10} className={styles.maxWidthContent}>
          {props.children}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

Header.defaultProps = {};

export default Header;
