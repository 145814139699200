import {
  call, put, takeLeading,
} from 'redux-saga/effects';
import { parseApiError } from 'common/helpers/utils';
import Widget from 'services/widgets';

import {
  widgetDataRequested,
  widgetDataSucceeded,
  widgetDataFailed,
} from './slice';

export function* handleFetchWidgetData({ payload }) {
  const results = {};
  try {
    for (let i = 0; i < payload.length; i += 1) {
      const { type } = payload[i];
      const params = { type };
      if (type === 'timeframe' || type === 'timeframe_enroll') {
        params.scale = payload[i].scale;
      }
      if (payload[i].extra) {
        params.extra = JSON.stringify(payload[i].extra);
      }
      const { data } = yield call(Widget.getWidgetsData, params);
      results[type] = data;
    }
    yield put(widgetDataSucceeded(results));
  } catch (e) {
    yield put(widgetDataFailed(parseApiError(e)));
  }
}

const watchers = [
  takeLeading(widgetDataRequested.type, handleFetchWidgetData),
];

export default watchers;
