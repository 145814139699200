import React, { FC, memo } from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { get as _get } from 'lodash';
import { isNull } from 'lodash';
import useStyles from './style';

function FormInput({
  name,
  control,
  errors,
  onChange: changedFn,
  defaultValue,
  isDisabled,
  whiteBackground,
  ...inputProps
}) {
  const classes = useStyles();
  const isError = _get(errors, name) && _get(errors, name).message;

  const textFieldChanged = (event) => {
    if (changedFn) {
      changedFn(event.target.name, event.target.value);
    }
  };

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        defaultValue={!isNull(defaultValue) ? defaultValue : ''}
        render={({ onChange, onBlur, ref, ...defaultProps }) => (
          <TextField
            error={_get(errors, name) ? true : false}
            onChange={(event) => {
              onChange(event);
            }}
            onBlur={(event) => {
              textFieldChanged(event);
              onBlur(event);
            }}
            inputRef={ref}
            disabled={isDisabled}
            className={
              whiteBackground ? classes.whiteBackground : classes.marginTop
            }
            {...defaultProps}
            {...inputProps}
          />
        )}
      />
      {isError && (
        <Typography
          className={classes.error}
          variant="caption"
          display="block"
          gutterBottom
        >
          {_get(errors, name).message}
        </Typography>
      )}
    </Box>
  );
}

FormInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  control: PropTypes.any,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  helperText: PropTypes.string,
};

FormInput.defaultProps = {
  isDisabled: false,
  helperText: '',
  whiteBackground: false,
};

export default memo(FormInput);
