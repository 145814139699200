/* eslint-disable max-len */

const constants = {};

export const REGEX = {
  // Official Standard: RFC 5322
  // The API uses this regex to check emails, we have to use the same one
  // otherwise the BE may reject emails that the FE approves
  // eslint-disable-next-line no-useless-escape
  emailValidation: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneValidation: /^[0-9]{10}$/,
  passwordLengthValidation: /^(.{5,}[^\s+])$/,
  passwordLengthAdditionalValidation: /^(((?=.*[a-z])(?=.*[0-9])(?=.{5,}\W))|^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|^((?=.*[a-z]))(?=.*[A-Z])(?=.{5,}\W))/,
  customDataSource: /^custom_[0-9]*$/,
};

export const DATE_TIME_FORMATS = {
  time: 'HH:mm:ss',
  timeAmPm: 'hh:mm a',
  date: 'MM/dd/yyyy',
  outputDate: 'yyyy-MM-dd',
  hyphenDate: 'yyyy-MM-dd',
  stringDate: 'MMMM dd, yyyy', // 2021 March 24
  dateTime: 'MM/dd/yyyy hh:mm a',
  fullDateTime: 'MM/dd/yyyy hh:mm:ss a',
  outputDateTime: 'yyyy-MM-dd HH:mm:ss',
  maxDate: '2100-01-01',
  minDate: '1900-01-01',
  eventDate: 'E, MMMM do, yyyy', // 'Mon, June 28th, 2021'
  eventFullDate: 'PPPPp', // 'Monday, June 28th, 2021 at 11:20 AM'
};

export const APPLICATION_STATUS_COLORS = {
  1: '#84e0e3',
  0: '#f78c74',
  '-1': '#ffca89',
  notSubmitted: '#b0c0ca',
  withdrawn: '#dbe6ed',
};

export const LOTTERY_PUBLISH_COLORS = {
  Yes: '#64a257',
  No: '#99bdc9',
  Partially: '#e57800',
};

export const APPLICATION_STATUS_LABELS = {
  1: 'application.status.eligible',
  0: 'application.status.ineligible',
  '-1': 'application.status.inProcessing',
  eligible: 'application.status.eligible',
  ineligible: 'application.status.ineligible',
  inProcessing: 'application.status.inProcessing',
  submitted: 'application.status.submitted',
  notSubmitted: 'application.status.notSubmitted',
  withdrawn: 'application.status.withdrawn',
};

export const EVENT_STUDENT_STATUSES = {
  schedule: 1,
  reschedule: -1,
};

export const APPLICATION_SUBMISSION_STATUSES = {
  submitted: 1,
  notSubmitted: 0,
};

export const REGISTRATION_STATUS = {
  approved: 1,
  denied: 0,
  inProcessing: '-1',
};

export const REGISTRATION_ENROLL_TYPE = {
  reenroll: 'reenroll',
  enroll: 'enroll',
};

export const APPLICATION_ELIGIBILITY_STATUSES = {
  eligible: 1,
  ineligible: 0,
  inProcessing: -1,
};

export const APPLICATION_LOTTERY_RANKING_LIST = {
  offered: 'offered',
  waiting: 'waiting',
};

export const APPLICATION_LOTTERY_RANKING_STATUSES = {
  accepted: 1,
  declined: 0,
  pending: -1,
};

export const PARENT_APPLICATION_STATUS_COLORS = {
  1: '#2E8E55',
  0: '#EC5A5A',
  '-1': '#f7923d',
  withdrawn: '#dbe6ed',
  waitlisted: '#e77223',
};

export const TABLE_CONFIGS = {
  selectedColor: 'rgba(0, 158, 170, 0.12)',
};

export const DEFAULT_ORGANIZATION_ID = 1;

export const FORMTYPES = {
  APPLICATION: 1,
  ENROLLMENT: 2,
  STUDENT_INFORMATION: 3,
};

export const ENROLLMENTFLOWS = {
  APPLY: 'application',
  REGISTRATION: 'registration',
};

export const REGISTRATION_STATUS_COLORS = {
  1: 'rgba(110, 192, 93, 0.5)',
  0: '#f78c74',
  '-1': '#ffca89',
  notSubmitted: '#b0c0ca',
};

export const REGISTRATION_STATUS_LABELS = {
  1: 'registration.status.approved',
  0: 'registration.status.denied',
  '-1': 'registration.status.inProcessing',
  notSubmitted: 'registration.status.notSubmitted',
  approved: 'registration.status.approved',
  denied: 'registration.status.denied',
  inProcessing: 'registration.status.inProcessing',
};

export const SYSTEM_DATA_SOURCES = [
  'SchoolGroup',
  'School',
  'Program',
  'Grade',
];

export const BOUNDARY_TYPES = {
  DISTRICT_BOUNDARY: 'District Boundary',
  SCHOOL_ZONE: 'School Zone',
  PRIORITY_ZONE: 'Priority Zone',
};

export const MESSAGES_STATUS_MAPPING = {
  rejected: 'error',
  invalid: 'error',
  failed: 'error',
  error: 'error',
  sent: 'sent',
  pending: 'pending',
};

export const WIDGET_DEFAULT_COLORS_RGBA = [
  'rgba(5, 41, 117)',
  'rgba(24, 167, 178)',
  'rgba(156, 191, 228)',
  'rgba(194, 215, 240)',
  'rgba(177, 152, 255)',
  'rgba(135, 98, 203)',
  'rgba(84, 67, 181)',
  'rgba(93, 140, 255)',
  'rgba(53, 182, 235)',
  'rgba(48, 216, 173)',
  'rgba(122, 242, 188)',
  'rgba(244, 228, 117)',
  'rgba(252, 182, 94)',
  'rgba(246, 142, 75)',
  'rgba(228, 102, 82)',
  'rgba(205, 77, 59)'
];

export const WIDGET_DEFAULT_COLORS = [
  '#052975',
  '#18A7B2',
  '#9CBFE4',
  // '#C2D7F0',
  '#B198FF',
  '#8762CB',
  '#5443B5',
  '#5D8CFF',
  '#35B6EB',
  '#30D8AD',
  '#7AF2BC',
  '#F4E475',
  '#FCB65E',
  '#F68E4B',
  '#E46652',
  '#CD4D3B',
];

export const PHONE_NUMBER_FORMATS = {
  tenDigit: '(999) 999-9999',
};

export default constants;
