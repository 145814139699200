import PropTypes from 'prop-types';
import useTheme from './theme';
import { Box, Typography, CssBaseline, ThemeProvider } from '@material-ui/core';
import useStyles from './style';

function HeaderLegend({ primary, secondary }) {
  const theme = useTheme;
  const styles = useStyles();
  return (
    <Box pr={4}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>
          <Typography
            variant="h6"
            display="inline"
            color="textPrimary"
            className={`${styles.containerText} ${styles.textWidth}`}
          >
            {primary}
          </Typography>
          {primary && (
            <Typography
              variant="h6"
              display="inline"
              color="textPrimary"
              className={styles.containerText}
            >
              &nbsp;-&nbsp;
            </Typography>
          )}
          <Typography
            variant="h6"
            display="inline"
            color="textSecondary"
            className={styles.containerText}
          >
            {secondary}
          </Typography>
        </div>
      </ThemeProvider>
    </Box>
  );
}

HeaderLegend.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
};

HeaderLegend.defaultProps = {};
export default HeaderLegend;
