import { Tooltip as MuiTooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './style';

function Tooltip({ children, title, placement, interactive }) {
  if (!title) {
    return children;
  }
  const styles = useStyles();
  return (
    <MuiTooltip
      title={<div style={{ whiteSpace: 'pre-line' }}>{title}</div>}
      placement={placement}
      classes={{ tooltip: styles.tooltip }}
      arrow
      interactive={interactive}
    >
      {children}
    </MuiTooltip>
  );
}

export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placement: PropTypes.string,
  interactive: PropTypes.bool,
};

Tooltip.defaultProps = {
  children: <></>,
  placement: 'top',
  interactive: false,
};
