import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.common.tableRowConstract,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.tableRowHover,
    },
    '&.deleted': {
      backgroundColor: alpha(theme.palette.common.negativeRed, 0.1),
    },
    '&.deleted:nth-of-type(odd)': {
      backgroundColor: alpha(theme.palette.common.negativeRed, 0.2),
    },
    '&.deleted:hover': {
      backgroundColor: alpha(theme.palette.common.negativeRed, 0.3),
    },
  },
}))(TableRow);

export default StyledTableRow;
