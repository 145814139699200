import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    fontFamily: 'Open Sans',
    h6: {
      fontSize: '0.8125rem',
      fontWeight: 700,
      color: '#ffffff',
    },
  },
  palette: {
    text: {
      primary: '#ffffff',
      secondary: '#06c9cf',
    },
  },
});
