import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  cell: {
    borderRightStyle: 'none',
    borderLeftStyle: 'none',
    background: theme.palette.common.tableRow,
    height: '40px',
    '&.negative:hover, &.positive:hover, &.neutral:hover, &.primary:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.0),
    },
  },
  boxBackground: { backgroundColor: theme.palette.primary.color },
}));
