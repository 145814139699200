import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  whiteBackground: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    marginTop: `${theme.spacing(1)}px`,
  },
  marginTop: {
    marginTop: `${theme.spacing(1)}px`,
  },
}));

export default Style;
