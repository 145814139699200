import axios from 'axios';
import { apiV1 } from 'configs/api';

const Transforms = {
  getTransformations() {
    return axios.get(apiV1('transform'));
  },
  transform(payload) {
    return axios.post(apiV1(`transform`), { data: payload });
  },
};

export default Transforms;
