import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { get as _get, isEqual } from 'lodash';
import { Box, FormHelperText, TextField } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Progress } from 'components';
import useStyles from './style';

function FormSelect({
  defaultValue,
  name,
  label,
  options,
  control,
  groupBy,
  errors,
  isDisabled,
  isMulti,
  onChange: onChangeProp,
  isLoading,
  size,
  background,
  getOptionLabel,
  disableClearable,
  getOptionDisabled,
  startAdornment,
  freeSolo,
}) {
  const classes = useStyles();
  const inputRef = useRef(null);
  const isError = _get(errors, name) !== undefined;

  return (
    <Box mt={1} mb={1}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || null}
        onFocus={() => {
          inputRef.current.focus();
        }}
        render={({ onChange, onBlur, value, name, ref }) => {
          return (
            <Autocomplete
              disabled={isDisabled}
              disableClearable={disableClearable}
              className={
                background != 'none' && classes[background]
                  ? classes[background]
                  : ''
              }
              size={size}
              options={options}
              value={value}
              groupBy={groupBy}
              filterSelectedOptions
              getOptionLabel={getOptionLabel}
              getOptionSelected={(option, value) => isEqual(option, value)}
              freeSolo={freeSolo}
              renderInput={(params) => {
                if (isLoading) {
                  return <Progress />;
                }
                let inputProps = {
                  ...params.InputProps,
                  autoComplete: 'new-password',
                };
                if (startAdornment) {
                  inputProps['startAdornment'] = startAdornment;
                }

                return (
                  <TextField
                    {...params}
                    error={isError}
                    InputProps={inputProps}
                    onBlur={onBlur}
                    name={name}
                    inputRef={inputRef}
                    label={label}
                    disabled={isDisabled}
                    variant="outlined"
                  />
                );
              }}
              onChange={(_, data) => {
                onChange(data);
                if (onChangeProp) {
                  if (isMulti) {
                    if (Array.isArray(data)) {
                      onChangeProp(
                        name,
                        data.map((option) => option.value)
                      );
                    } else {
                      // form clear option
                      onChangeProp(name, []);
                    }
                  } else {
                    // sigle option dropdown
                    if (data && !Array.isArray(data)) {
                      onChangeProp(name, data.value);
                    } else {
                      // form clear option
                      onChangeProp(name, '');
                    }
                  }
                }
              }}
              multiple={isMulti}
              getOptionDisabled={getOptionDisabled}
            />
          );
        }}
      />
      {isError && (
        <FormHelperText className={classes.error}>
          {_get(errors, name).message}
        </FormHelperText>
      )}
    </Box>
  );
}

FormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  errors: PropTypes.object,
  control: PropTypes.any,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  background: PropTypes.string,
  disableClearable: PropTypes.bool,
  groupBy: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  startAdornment: PropTypes.element,
  freeSolo: PropTypes.bool,
};

FormSelect.defaultProps = {
  freeSolo: false,
  isLoading: false,
  isMulti: false,
  isDisabled: false,
  size: 'medium',
  background: 'none',
  onChange: () => {},
  disableClearable: false,
  groupBy: () => {},
  getOptionLabel: (option) =>
    option && option.label ? option.label.toString() : '',
  getOptionDisabled: () => {},
};

export default FormSelect;
