import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: '0.75rem',
      fontWeight: 600,
      opacity: '0.76 !important',
    },
  })
);
