/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import {
  Button,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  ClickAwayListener,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import sharedStyles from './sharedStyles';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 10,
  },
  ...sharedStyles,
}));

const HeaderMenu = ({
  buttonContent,
  items,
  onClick,
  buttonColor,
  buttonClass,
  buttonVariant,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const styles = useStyles();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const onClickItem = (key) => {
    setOpen(false);
    onClick(key);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        color={buttonColor}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="Header button"
        onClick={handleToggle}
        className={buttonClass}
        variant={buttonVariant}
      >
        {buttonContent}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={styles.root}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {Object.entries(items).map(([key, value]) => (
                    <MenuItem key={key} onClick={() => onClickItem(key)}>
                      <Typography
                        color="textPrimary"
                        className={styles.headerMenuItem}
                      >
                        {value}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

HeaderMenu.defaultProps = {
  buttonColor: 'inherit',
  buttonClass: '',
  buttonVariant: 'text',
};

HeaderMenu.propTypes = {
  buttonContent: PropTypes.element.isRequired,
  items: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  buttonColor: PropTypes.string,
  buttonClass: PropTypes.string,
  buttonVariant: PropTypes.string,
};

export default HeaderMenu;
