import {
  call, put, takeLeading, takeEvery, all,
} from 'redux-saga/effects';
import { parseApiError } from 'common/helpers/utils';
import SemAnalytics from 'services/semAnalytics';

import {
  semAnalyticsOneDataRequested,
  semAnalyticsOneDataFinished,
} from './slice';

function* safe(effect) {
  try {
    return { response: yield effect };
  } catch (error) {
    return { error };
  }
}

export function* handleFetchSemAnalyticsOneData({ payload }) {
  if (payload.skip) {
    console.log('skipping because you told us to, likely because this client doesn\'t use this product');
    return;
  }
  try {
    const params = payload;

    if (params.extra) { // just do an isObject on each key/value?
      params.extra = JSON.stringify(params.extra);
    }

    const result = yield safe(call([SemAnalytics, SemAnalytics.getData], params));
    const results = {};

    const { response } = result;

    results[params.type] = response ? response.data : result;

    yield put(semAnalyticsOneDataFinished(results));
  } catch (e) {
    console.log("I don't think we ever get here");
    console.error(e);
    yield put(semAnalyticsOneDataFinished(parseApiError(e)));
  }
}

const watchers = [
  takeEvery(semAnalyticsOneDataRequested.type, handleFetchSemAnalyticsOneData),
];

export default watchers;
