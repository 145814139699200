import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ModalAlert({
  title,
  content,
  innerHTML,
  onAgree,
  onDisagree,
  agreeText,
  disagreeText,
  open,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" disableTypography>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          color="secondary"
          component="span"
          dangerouslySetInnerHTML={
            innerHTML ? { __html: innerHTML } : undefined
          }
        >
          {content ? content : undefined}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {disagreeText && onDisagree && (
          <Button onClick={onDisagree} color="primary">
            {disagreeText}
          </Button>
        )}
        {agreeText && onAgree && (
          <Button onClick={onAgree} color="primary">
            {agreeText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalAlert.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node,
  innerHTML: PropTypes.node,
  agreeText: PropTypes.string.isRequired,
  disagreeText: PropTypes.string,
  open: PropTypes.bool,
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

ModalAlert.defaultProps = {
  open: false,
  content: '',
};

export default ModalAlert;
