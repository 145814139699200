import { createTheme } from '@material-ui/core/styles';

const typography = {
  fontFamily: 'Open Sans, Raleway',
  caption: {
    fontStyle: 'italic',
    fontSize: '0.6875rem',
    fontWeight: '400',
    color: 'rgba(7, 32, 68, 0.6)',
  },
  underline: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  h1: {
    fontSize: '2.75rem',
    fontFamily: 'Raleway',
    fontWeight: 400,
    color: '#072044',
  },
  h2: {
    fontSize: '0.9375rem',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    color: '#072044',
  },
  h4: {
    fontSize: '1.625rem',
    fontFamily: 'Raleway',
    fontWeight: 650,
    color: '#072044',
  },
  h5: {
    fontSize: '1.025rem',
    fontFamily: 'Raleway',
    fontWeight: 700,
    color: '#072044',
  },
  h6: {
    fontSize: '0.25rem',
    fontWeight: 100,
    color: '#004968',
  },
  subtitle1: {
    fontSize: '0.8125rem',
    fontWeight: '600',
    color: 'rgba(7, 32, 68, 0.6)',
  },
  subtitle2: {
    fontSize: '0.750rem',
    fontWeight: '600',
    color: 'rgba(7, 32, 68, 0.6)',
  },
  body1: {
    fontSize: '0.6875rem',
    fontWeight: '400',
    color: '#072044',
  },
  body2: {
    fontSize: '0.75rem',
    fontWeight: '600',
    color: '#072044',
  },
  button: {
    textTransform: 'none',
    fontSize: '0.8125rem',
    fontWeight: '700',
  },
};

const palette = {
  common: {
    tableZebra: '#f5fafb',
    tableRow: 'rgba(131, 181, 201, 0)',
    tableRowConstract: 'rgba(131, 181, 201, 0.1)',
    tableRowHover: '#e0f3f4',
    borders: '#d4e5f9',
    bordersSecondary: '#a5c5d2',
    lightBlueGrey: '#b2e1e5',
    neutralGrey: '#749fb0',
    neutralGrey2: '#4c8096',
    lightGrey: 'rgba(7, 32, 68, 0.5)',
    negative: '#ec5a5a',
    marine40: 'rgb(7,32,68,0.40)',
    greenPositive: '#64a257',
    greenPositive2: '#2e8e55',
    yellowNegative: '#ffb936',
    chipDisabledColor: '#dbe6ed',
    switchTrack: '#b0c0ca',
    disabled: '#d6e4e9',
    transform: '#f3f6fa',
    negativeRed: '#fb7a7a',
    red: '#FF0000',
    orange: '#e98b24',
    redBorderError: '#F08787',
    redBackgroundError: '#FEF2F2',
    black: '#000000',
    white: '#ffffff',
  },
  text: {
    primary: '#072044',
    secondary: '#009EAA',
  },
  primary: {
    main: '#009EAA',
    color: '#ffffff',
  },
  secondary: {
    main: '#072044',
    light: '#004968',
  },
  error: {
    main: '#EC5A5A',
  },
  background: {
    default: '#ffffff',
    secondary: '#fafafa',
  },
  textPrimary: {
    default: '#0f0',
  },
  success: {
    main: '#6ec05d',
  },
};

export const overrides = {
  MuiOutlinedInput: {
    root: {
      '& $notchedOutline': {
        borderColor: palette.common.borders,
      },
      '&:hover $notchedOutline': {
        borderColor: palette.primary.main,
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      color: 'rgba(7, 32, 68, 0.6)',
    },
  },
  MuiButton: {
    outlined: {
      fontSize: '0.8125rem',
      fontWeight: '600',
      backgroundColor: palette.background.default,
    },
    contained: {
      '&$disabled': {
        backgroundColor: palette.common.disabled,
        color: 'white',
      },
    },
  },
  MuiCard: {
    root: {
      borderColor: palette.common.borders,
    },
  },
  MuiSwitch: {
    track: {
      backgroundColor: palette.common.switchTrack,
      opacity: '100%',
    },
  },
};

const theme = createTheme({
  typography,
  palette,
  overrides,
});
export default theme;
