import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Grid,
  Popper,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  ClickAwayListener,
  Typography,
} from '@material-ui/core';
import useStyles from './style';
import { useHistory } from 'react-router-dom';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function HeaderButton({ children, onClick, href, target, subMenu }) {
  const styles = useStyles();
  const history = useHistory();
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const onClickSubMenu = () => {
    setOpenSubMenu(!openSubMenu);
  };
  const anchorRef = React.useRef(null);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenSubMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSubMenu(false);
    } else if (event.key === 'Escape') {
      setOpenSubMenu(false);
    }
  }
  return (
    <Grid item>
      <Box height="100%" xs={3} borderLeft={1} className={styles.borderBox}>
        <Button
          onClick={() => {
            if (href) {
              if (target == '_blank') {
                window.open(href, '_blank');
                return;
              }
              history.push(href);
            } else {
              onClick();
            }
          }}
          style={{ height: '100%' }}
          fullWidth
          className={styles.button}
        >
          <Box
            ref={anchorRef}
            marginLeft={1}
            marginRight={1}
            fontWeight={700}
            pt={3}
            pb={3}
            onClick={() => {
              if (subMenu) {
                onClickSubMenu();
              }
            }}
            color="common.white"
          >
            <Grid container direction="row">
              <Grid item>{children}</Grid>
              <Grid item>
                {subMenu && (
                  <Box>
                    {openSubMenu ? (
                      <ExpandLessIcon
                        fontSize="small"
                        style={{ position: 'absolute', right: 18, top: 32 }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        style={{ position: 'absolute', right: 18, top: 32 }}
                      />
                    )}
                    <Box paddingRight={3} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Button>
        {subMenu && (
          <Popper
            open={openSubMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={openSubMenu}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {subMenu.map((menuItem) => {
                        return (
                          <MenuItem
                            key={menuItem.label}
                            onClick={() => {
                              history.push(menuItem.href);
                              setOpenSubMenu(false);
                            }}
                          >
                            {menuItem.label}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </Box>
    </Grid>
  );
}

HeaderButton.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
    })
  ),
};

HeaderButton.defaultProps = {
  target: '',
  href: null,
  onClick: () => {},
  subMenu: null,
};
export default HeaderButton;
