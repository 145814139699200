import adminLogo from 'assets/images/logo.png';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

function Logo() {
    const history = useHistory();
    return (
        <Button
            onClick={() => {
                history.push('/');
            }}
        >
            <img src={adminLogo} alt="logo" height="34" />
        </Button>
    );
}

export default Logo;
