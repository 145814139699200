import React, { FC, memo } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import PropTypes from 'prop-types';
import useStyles from './style';

const FormButton = ({
  value,
  children,
  isSubmitting = false,
  className,
  size,
  ...props
}) => {
  const classes = useStyles();

  const isContainedAndPrimary =
    props.variant == 'contained' && props.color == 'primary';
  return (
    <Button {...props} className={`${classes.button} ${className}`} size={size}>
      {isSubmitting ? (
        <>
          {value}&nbsp;
          <CircularProgress
            className={isContainedAndPrimary ? classes.progressIcon : ''}
            size={size == 'small' ? 22 : 23}
          />
        </>
      ) : (
        <>
          {value}&nbsp;{children}
        </>
      )}
    </Button>
  );
};

FormButton.propTypes = {
  children: PropTypes.node,
  value: PropTypes.node,
  isSubmitting: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
  color: PropTypes.string,
};

FormButton.defaultProps = {
  isSubmitting: false,
  className: '',
  size: 'medium',
};

export default memo(FormButton);
