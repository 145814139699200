import { makeStyles, createStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) =>
  createStyles({
    button: {
      color: '#ffffff',
      borderRadius: 0,
    },
    borderBox: {
      borderColor: theme.palette.secondary.dark,
      boxShadow:
        '-1px 0px 0px 0px ' + alpha(theme.palette.secondary.light, 0.7),
    },
    marginTopSubMenu: {
      marginTop: theme.spacing(1),
    },
  })
);
