import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  transformations: [],
  isLoadingTransformations: false,
  isLoadingTransform: false,
  dataTransformed: {},
  transformError: null,
  transformSucceeded: false,
};

const slice = createSlice({
  name: 'transforms',
  initialState,
  reducers: {
    loadTransformations: (state) => {
      state.isLoadingTransformations = true;
    },
    loadTransformationsSucceeded: (state, action) => {
      state.isLoadingTransformations = false;
      var transformationsFormatted = {};
      Object.keys(action.payload.transformations).map((key) => {
        var transformation = action.payload.transformations[key];
        transformation['key'] = key;
        transformationsFormatted[key] = transformation;
      });
      state.transformations = transformationsFormatted;
    },
    loadTransformationsFailed: (state) => {
      state.isLoadingTransformations = false;
    },
    transform: (state) => {
      state.isLoadingTransform = true;
      state.transformSucceeded = false;
      state.transformError = null;
    },
    transformSucceeded: (state, action) => {
      state.dataTransformed = Object.assign(
        state.dataTransformed,
        action.payload.data
      );
      state.isSaving = false;
      state.saveSucceeded = true;
      state.saveError = null;
    },
    transformFailed: (state, action) => {
      state.isLoadingTransform = false;
      state.transformSucceeded = true;
      state.transformError = action.payload.errorMessage;
    },
    removeTransform: (state, action) => {
      var mapping = { ...state.dataTransformed };
      delete mapping[action.payload];
      state.dataTransformed = mapping;
    },
    setTransform: (state, action) => {
      state.dataTransformed = Object.assign(
        state.dataTransformed,
        action.payload.data
      );
    },
    resetTransformedData: (state, action) => {
      state.dataTransformed = {};
    },
  },
});

export const {
  loadTransformations,
  loadTransformationsSucceeded,
  loadTransformationsFailed,
  transform,
  setTransform,
  transformSucceeded,
  transformFailed,
  removeTransform,
  resetTransformedData,
} = slice.actions;

export const selectTransformations = (state) =>
  state.transforms.transformations;
export const selectTransformedData = (state) =>
  state.transforms.dataTransformed;
export const selectIsLoadingTransform = (state) =>
  state.transforms.isLoadingTransform;
export const selectTransformSucceeded = (state) =>
  state.transforms.transformSucceeded;
export const selectTransformFailed = (state) =>
  state.transforms.transformFailed;

export default slice.reducer;
