import React from 'react';
import PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router';
import { ModalAlert } from 'components';

function PromptAlert({
  title,
  content,
  agreeText,
  disagreeText,
  openWhen,
  backToNormalCallback,
}) {
  const history = useHistory();
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [nextUrl, setNextUrl] = React.useState(null);

  return (
    <>
      <Prompt
        when={openWhen}
        message={(event) => {
          if (isLeaving) {
            return true;
          }

          setIsLeaving(true);
          setNextUrl(event.pathname);
          return false;
        }}
      />

      {isLeaving && (
        <ModalAlert
          open={openWhen}
          title={title}
          content={content}
          agreeText={agreeText}
          onAgree={() => {
            backToNormalCallback();
            setIsLeaving(false);
            history.push(nextUrl);
          }}
          disagreeText={disagreeText}
          onDisagree={() => {
            setIsLeaving(false);
            setNextUrl(null);
          }}
          onClose={() => {
            setIsLeaving(false);
            setNextUrl(null);
          }}
        />
      )}
    </>
  );
}

PromptAlert.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  agreeText: PropTypes.string.isRequired,
  disagreeText: PropTypes.string.isRequired,
  openWhen: PropTypes.bool.isRequired,
  backToNormalCallback: PropTypes.func.isRequired,
};

PromptAlert.defaultProps = {};

export default PromptAlert;
