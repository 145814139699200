import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box } from '@material-ui/core';
// import { Loader } from 'components/Loader'
import loaderSvg from 'assets/loaders/leaves-pulse.svg';
import useStyles from './style';
import { makeStyles } from '@material-ui/core/styles';

function Progress({size}) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      m={1}
      p={1}
      className={classes[size]}
    >
      <Box justifyContent="center" textAlign="center">
        <object
          className={classes[`${size}Animation`]}
          type="image/svg+xml"
          data={loaderSvg}>
          svg-animation
        </object>
        {/* <CircularProgress /> */}
        {/* <div className={classes.loadingText}>Loading</div> */}
      </Box>
    </Box>
  );
}

Progress.propTypes = {
  size: PropTypes.string,
};

Progress.defaultProps = {
  size: 'full',
};

export default Progress;
