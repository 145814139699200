import React from 'react';
import { Grid, Box } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/Forward';

const HeaderToggleIcon = () => {
  return (
    <div>
      <Box
        border={3}
        maxHeight={50}
        maxWidth={50}
        minHeight={50}
        minWidth={50}
        position="absolute"
        zIndex={99}
        marginX={-3}
        marginY={-3}
        borderColor="common.greenPositive"
        color="common.greenPositive2"
        borderRadius={25}
        style={{ backgroundColor: 'white' }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box pt={1} pl={0.1}>
              <ForwardIcon color="inherit" style={{ fontSize: '2rem' }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

HeaderToggleIcon.propTypes = {};

HeaderToggleIcon.defaultProps = {};

export default HeaderToggleIcon;
