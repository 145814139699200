import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const StyledButton = withStyles((theme) => ({
  root: {
    border: 'solid 2px ' + alpha(theme.palette.common.white, 0),
    borderLeft: 'solid 1px ' + theme.palette.common.borders,
    borderRight: 'solid 1px ' + theme.palette.common.borders,
    backgroundColor: theme.palette.common.white,
    '&.selected.negative': {
      border: 'solid 2px ' + alpha(theme.palette.common.negativeRed, 0.5),
      backgroundColor: alpha(theme.palette.common.negativeRed, 0.05),
    },
    '&.negative': {
      color: theme.palette.common.negativeRed,
    },
    '&.selected.positive': {
      border: 'solid 2px ' + alpha(theme.palette.common.greenPositive2, 0.5),
      backgroundColor: alpha(theme.palette.common.greenPositive2, 0.05),
    },
    '&.positive': {
      color: theme.palette.common.greenPositive2,
    },
    '&.selected.neutral': {
      border: 'solid 2px ' + alpha(theme.palette.common.neutralGrey2, 0.5),
      backgroundColor: alpha(theme.palette.common.neutralGrey2, 0.05),
    },
    '&.neutral': {
      color: theme.palette.common.neutralGrey2,
    },
    '&.selected.primary': {
      border: 'solid 2px ' + alpha(theme.palette.primary.main, 0.5),
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    '&.primary': {
      color: theme.palette.primary.main,
    },
    borderRadius: 0,
  },
}))(Button);

export default StyledButton;
