import axios from 'axios';
import { apiV1 } from 'configs/api';

const Adapters = {
  getAdapters() {
    return axios.get(apiV1('adapters'));
  },
  getConnectionAdapters(connectionId) {
    return axios.get(apiV1(`connections/${connectionId}/adapters`));
  },
};

export default Adapters;
