import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  clientName: "DPS",
  unsavedChanges: false,
  settings: {
    "languages": [
      "en",
      "es"
    ],
    "active_school_year_start": 2022,
    "active_school_year_end": 2023,
    "active_school_year_start_short": "22",
    "active_school_year_end_short": "23",
    "default_school_year_start": 2022,
    "schoolYear": 2022
  },
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startBoot: (state) => {
      state.isLoading = true;
    },
    completeBoot: (state, action) => {
      state.isLoading = false;
      // const clientAdapter = action.payload.adapters.data
      //   ? action.payload.adapters.data.find(
      //     (adapter) =>
      //       adapter.id == action.payload.userData.data.session.client
      //   )
      //   : {};

      // state.clientName = clientAdapter.name ?? '';
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload.unsavedChanges;
    },
  },
});

export const { startBoot, completeBoot, setUnsavedChanges } = slice.actions;

export const selectIsLoading = (state) => state.app.isLoading;
export const selectUnsavedChanges = (state) => state.app.unsavedChanges;
export const selectClientName = (state) => state.app.clientName;
export const selectSettings = (state) => state.app.settings;

export default slice.reducer;
