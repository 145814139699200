import axios from 'axios';
import Auth from 'services/auth';
import Cookies from 'js-cookie';

export const loadApiConfig = () => {
  axios.defaults.baseURL = baseUrl();
};

export const baseUrl = () => process.env.REACT_APP_API_HOST;

export const apiV1 = (path) => {
  return `/v1/${path}`;
};

// taken from enrollment-fe, meant for hitting enrollment-be
export const services = {
  core: (url, version = 'v1') => `/core/${version}${url}`,
  adminCore: (url, version = 'v1') => `/core/${version}/admin${url}`,
  parentCore: (url, version = 'v1') => `/core/${version}/parents${url}`,
};

export const addRequestHeader = (name, value) => {
  axios.defaults.headers.common[name] = value;
};

export const removeRequestHeader = (name) => {
  delete axios.defaults.headers.common[name];
};

export const unauthorizeInterceptor = () => {
  axios.interceptors.response.use({}, (error) => {
    const errorResponse = error.response;

    if (!errorResponse) {
      // error.errorMessage = error;
      return Promise.reject(error);
    }

    if (errorResponse.status === 401) {
      Auth.logOut();
      return (window.location.href = '/');
    }
    if (errorResponse.data.errors) {
      if (typeof errorResponse.data.errors === 'object') {
        // TODO: make this nicer so we can show the error alert.
        error.errorMessage = JSON.stringify(errorResponse.data.errors);
      } else {
        error.errorMessage = errorResponse.data.errors;
      }
    }

    return Promise.reject(error);
  });
};
