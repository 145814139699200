import { takeLeading, put, call } from 'redux-saga/effects';
import history from 'configs/history';
import { loadSession, loadSessionCompleted, loadSessionFailed } from './slice';
import Auth from 'services/auth';
import { initiateApp } from 'store/app/saga';

export function* loadCurrentUserData() {
  if (Auth.isLoggedIn()) {
    Auth.setClientHeader();
    Auth.setAuthorizationHeader();
    try {
      const response = yield call(Auth.userData);
      yield put(loadSessionCompleted(response.data));

      const session = response.data?.session;
      if (session) {
        try {
          window.analytics = _smAnalytics.initAnalytics({
            appName: 'insights-fe',
            smPluginConfig: {
              organization_name: session?.organization?.domain,
              organization_id: session?.organization?.id
            },
          })

          analytics.identify(session?.user?.id, {
            firstName: session?.user?.first_name,
            lastName: session?.user?.last_name,
            email: session?.user?.email
          })

          history.listen(() => {
            analytics.page()
          })
        } catch (error) {
          // e.g. if no org info passed, we can't init analytics
          console.log(error);
        }
      }

      history.push('/');
      return response;
    } catch (e) {
      yield put(loadSessionFailed(e));
      history.push('/welcome');
    }
  }
}

export function* handleLoadSessionFromToken(action) {
  try {
    // must set the client header
    yield call(Auth.setClientHeader, action.payload.client);
    const response = yield call(Auth.userSignin, {
      login_token: action.payload.loginToken,
    });
    // store the cookie to be send from now on
    yield Auth.setAuth(response.data);
    yield put(loadSessionCompleted(response.data));
    yield call(initiateApp);
    // take the user to home page
    history.push('/');
  } catch (e) {
    yield put(loadSessionFailed(e));
    history.push('/welcome');
  }
  return {};
}

const watchers = [takeLeading(loadSession.type, handleLoadSessionFromToken)];

export default watchers;
