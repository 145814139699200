import axios from 'axios';
import Cookies from 'js-cookie';
import {
  apiV1,
  addRequestHeader,
  removeRequestHeader,
  unauthorizeInterceptor,
} from 'configs/api';

const Auth = {
  userSignin(data) {
    return axios.post(apiV1('auth/login'), data);
  },
  userData() {
    return axios.get(apiV1('auth/user'));
  },
  setAuth(payload) {
    const data = payload || {};
    Cookies.set('token', data.token);
    this.setAuthorizationHeader();
  },
  isLoggedIn() {
    const token = Cookies.get('token');
    return token && token !== 'undefined';
  },
  setClientHeader(client) {
    if (client) {
      Cookies.set('client', client);
    } else {
      client = Cookies.get('client');
    }
    addRequestHeader('client', client);
  },
  setAuthorizationHeader() {
    const token = Cookies.get('token');
    addRequestHeader('Authorization', `Bearer ${token}`);
  },
  triggerLogOutRequest() {
    return axios.delete(apiV1('/auth/log-out'));
  },
  logOut() {
    Cookies.remove('token');
    Cookies.remove('client');
    removeRequestHeader('Authorization');
  },
};

export default Auth;
