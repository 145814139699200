import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  error: null,
  widgetData: {},
  isFetchingWidgetData: true,
  widgetDataSucceeded: false,
};

export const slice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    widgetDataRequested: (state) => {
      state.error = null;
      state.isFetchingWidgetData = true;
      state.widgetDataSucceeded = false;
      state.widgetData = {};
    },
    widgetDataSucceeded: (state, action) => {
      const { widgetData } = state;
      state.widgetData = { ...widgetData, ...action.payload };
      state.error = null;
      state.isFetchingWidgetData = false;
      state.widgetDataSucceeded = true;
    },
    widgetDataFailed: (state, action) => {
      state.error = action.payload;
      state.isFetchingWidgetData = false;
      state.widgetDataSucceeded = false;
      state.widgetData = {};
    },
    resetState: () => initialState,
  },
});

export const {
  widgetDataRequested,
  widgetDataSucceeded,
  widgetDataFailed,
  resetState,
} = slice.actions;

export const selectError = (state) => state.widgets.error;
export const selectIsFetchingWidgetData = (state) => state.widgets.isFetchingWidgetData;
export const selectWidgetDataSucceeded = (state) => state.widgets.widgetDataSucceeded;
export const selectWidgetData = (state) => state.widgets.widgetData;

export default slice.reducer;
