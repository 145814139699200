import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { selectIsAuthenticated } from 'store/auth/slice';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authenticated = useSelector(selectIsAuthenticated);

  const authenticationRedirect = (props) => {
    if (authenticated) {
      return <Component {...props} />;
    }

    return <Redirect to="/welcome" />;
  };

  return <Route {...rest} render={(props) => authenticationRedirect(props)} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
