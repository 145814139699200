import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const Style = makeStyles((theme) => ({
  button: {
    padding: `${theme.spacing(2) - 2}px ${theme.spacing(3)}px`,
  },
  progressIcon: {
    color: alpha(theme.palette.primary.contrastText, 0.9),
  },
}));

export default Style;
