import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Box } from '@material-ui/core';

const HeaderToggleButtonSubtitle = ({ children }) => {
  return (
    <Grid item>
      <Box
        borderRadius={25}
        border={3}
        p={0.5}
        pl={1}
        pr={1}
        minWidth={100}
        style={{ backgroundColor: 'white' }}
      >
        <Typography variant="subtitle1" color="inherit">
          {children}
        </Typography>
      </Box>
    </Grid>
  );
};

HeaderToggleButtonSubtitle.propTypes = {
  children: PropTypes.string.isRequired,
};

HeaderToggleButtonSubtitle.defaultProps = {};

export default HeaderToggleButtonSubtitle;
