import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((_theme) => ({
  containerText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    height: '18px',
  },
  textWidth: {
    maxWidth: '350px',
  },
}));

export default Style;
