import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import useStyles from './style';
import { FormButton, Tooltip } from 'components';
import { isJsonString } from '../../helpers';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';

const CommonTable = ({
  data,
  columns,
  cellValue,
  isFetching,
  onApplyFilters,
  onClearFilters,
  canDownload,
  type,
}) => {
  const styles = useStyles();
  const [tableSearch, setTableSearch] = useState('');
  const [tableData, setTableData] = useState(data);
  const calcTableContainerSize = (totalRows) => {
    const rowsToShow = 15;
    const rowHeight = 40;

    return totalRows > rowsToShow
      ? rowsToShow * rowHeight
      : totalRows * rowHeight;
  };

  React.useEffect(() => {
    setTableData(data);
    setTableSearch('');
  }, [data]);

  const getCellValue = ({ value, key, object }) => {
    value = cellValue({ value, key, object });
    return value;
  };

  const cellRenderer = ({ columnIndex, rowIndex, style, key }) => {
    if (tableData.length === 0) {
      return;
    }
    const isHeader = rowIndex == 0;
    const isOdd = rowIndex % 2 != 0;
    const value = isHeader
      ? columns[columnIndex].name
      : tableData[rowIndex - 1][columns[columnIndex].key];

    let className = '';
    className += ' ' + (isOdd ? styles.odd : '');
    className += ' ' + (isHeader ? styles.head : styles.body);
    className +=
      ' ' +
      (isHeader && columnIndex === 0 ? styles.headerColumnBordersLeft : '');
    return (
      <div
        className={className}
        title="cell"
        key={key}
        style={{ ...style }}
      >
        {isHeader
          ? value
          : getCellValue({
              value,
              key: columns[columnIndex].key,
              object: tableData[rowIndex - 1],
            })}
      </div>
    );
  };

  const columnWidth = (_index, width) => {
    return width / columns.length;
  };

  const onDownloadCSV = () => {
    let headers = columns.map((column) => column.name);
    let csv = headers.join();
    csv += '\n';

    tableData.forEach(function (row) {
      for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
        const key = columns[columnIndex].key;
        let value = row[key];
        value = getCellValue({
          value,
          key: columns[columnIndex].key,
          object: row,
        });

        const addEnter = columnIndex + 1 === columns.length;

        if (React.isValidElement(value)) {
          value = value?.props?.status;
        }
        value = value ? value.toString() : '';
        if (value.includes(',')) {
          if (isJsonString(value)) {
            value = value.replace(/"/g, "'");
          }
          value = `"${value}"`;
        }

        csv += value;
        csv += addEnter ? '\n' : ',';
      }
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.download = `${type}-Logs.csv`;
    hiddenElement.click();
  };

  let tableRef = null;
  return (
    <>
      <Grid container direction="row" justifyContent="space-around">
        <Box sx={{ m: 2, r: -10 }} />
        <Grid
          container
          direction="row"
          spacing={3}
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs>
            {tableData.length > 0 && (
              <Grid
                container
                direction="row"
                alignContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <FormButton
                    onClick={onDownloadCSV}
                    variant="outlined"
                    color="primary"
                    value="Download CSV"
                    size="small"
                    disableElevation
                    disabled={!canDownload}
                  >
                    <GetAppIcon />
                  </FormButton>
                </Grid>

                {!canDownload && (
                  <Grid item>
                    <Box color="common.neutralGrey">
                      <Tooltip title={'Wait for all the data to be fetched...'}>
                        <InfoIcon />
                      </Tooltip>
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Box sx={{ m: 2, r: -10 }} />
          <Grid item xs>
            <Grid
              justifyContent="center"
              container
              direction="row"
              spacing={3}
              alignContent="center"
              alignItems="center"
            >
              {tableData.length == 0 && (
                <Typography variant="subtitle1">
                  Please Apply filters to see results.
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <FormButton
                      onClick={onClearFilters}
                      variant="outlined"
                      color="primary"
                      value="Clear All Filters"
                      size="small"
                      disableElevation
                    />
                  </Grid>
                  <Grid item>
                    <FormButton
                      onClick={onApplyFilters}
                      variant="contained"
                      color="primary"
                      value="Apply Filters"
                      size="small"
                      disableElevation
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ m: 2, r: -10 }} />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Grid item xs>
          <Box
            borderRadius={4}
            border={tableData.length <= 0 ? 0 : 1}
            borderColor={tableData.length <= 0 ? '' : 'common.borders'}
            style={{
              height: calcTableContainerSize(tableData.length + 1),
              width: '100%',
            }}
          >
            <AutoSizer
              onResize={() => {
                tableRef.recomputeGridSize();
              }}
            >
              {({ height, width }) => (
                <MultiGrid
                  cellRenderer={cellRenderer}
                  classNameTopLeftGrid={styles.headerColumnBordersLeft}
                  classNameTopRightGrid={styles.headerColumnBordersRight}
                  columnWidth={(columnIndex) =>
                    columnWidth(columnIndex.index, width)
                  }
                  columnCount={columns.length}
                  fixedColumnCount={0}
                  fixedRowCount={1}
                  height={height}
                  rowHeight={40}
                  rowCount={tableData.length + 1}
                  width={width}
                  tabIndex={null}
                  ref={(ref) => {
                    tableRef = ref;
                  }}
                />
              )}
            </AutoSizer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

CommonTable.propTypes = {
  cellValue: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  columns: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  onApplyFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
  canDownload: PropTypes.bool,
  type: PropTypes.oneOf(['Import', 'Export']).isRequired,
};

CommonTable.defaultProps = {
  canDownload: false,
  cellValue: ({ value }) => value,
  pageSize: 100,
  onApplyFilters: () => {},
  onClearFilters: () => {},
};

export default CommonTable;
