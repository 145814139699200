import axios from 'axios';
import { services } from 'configs/api';

const Widget = {
  getWidgetsData(payload) {
    return axios.get(services.core('/service/widgets-data'), { params: payload });
  },
};

export default Widget;
