import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    maxWidthContent: {
      maxWidth: '1400px',
    },
    mainBox: {
      borderColor: theme.palette.primary.light,
      padding: '10px 0',
    },
  })
);
