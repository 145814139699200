import React from 'react';

import PromptAlert from 'components/PromptAlert';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnsavedChanges, setUnsavedChanges } from 'store/app/slice';

function UnsavedChangesPrompt({}) {
  const dispatch = useDispatch();
  const hasPendingChanges = useSelector(selectUnsavedChanges);

  return (
    <PromptAlert
      title="There are some unsaved changes."
      content="Are you sure you want to continue without saving?"
      agreeText="OK, CONTINUE"
      disagreeText="CANCEL"
      backToNormalCallback={() => {
        dispatch(setUnsavedChanges({ unsavedChanges: false }));
      }}
      openWhen={hasPendingChanges}
    />
  );
}

UnsavedChangesPrompt.propTypes = {};

UnsavedChangesPrompt.defaultProps = {};

export default UnsavedChangesPrompt;
