import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';

const HeaderToggleButtonTitle = ({ children }) => {
  return (
    <Grid item>
      <Typography variant="h3" color="inherit">
        {children}
      </Typography>
    </Grid>
  );
};

HeaderToggleButtonTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

HeaderToggleButtonTitle.defaultProps = {};

export default HeaderToggleButtonTitle;
