const useStyles = {
  headerText: {
    fontSize: '0.8125rem',
    fontWeight: '600',
    textTransform: 'none',
    marginLeft: '10px',
  },

  linkContainer: {
    alignItems: 'center',
    display: 'flex',
  },

  headerIcon: {
    opacity: '0.4 !important',
    visibility: 'visible',
  },

  headerButton: {
    minWidth: 'auto',
  },

  headerMenuItem: {
    fontSize: '0.875rem',
  },
};

export default useStyles;
