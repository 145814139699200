import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Hidden, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  setSchoolYear,
  selectSchoolYear,
  selectSchoolYears,
} from 'store/auth/slice';
import sharedStyles from './sharedStyles';
import HeaderMenu from './HeaderMenu';
import * as Sentry from '@sentry/react';

const { headerMenuItem } = sharedStyles;
const useStyles = makeStyles({
  headerMenuItem,
  yearText: {
    fontSize: '0.75rem',
    fontWeight: '600',
    textTransform: 'none',
    marginRight: '10px',
  },

  yearButtonText: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  yearButton: {
    height: '24px',
    borderRadius: '18px',
    paddingRight: '9px',
  },
});

const SchoolYearSelector = () => {
  const dispatch = useDispatch();
  const schoolYear = useSelector(selectSchoolYear);
  const schoolYears = useSelector(selectSchoolYears);
  const styles = useStyles();

  React.useEffect(() => {
    // tag error events with school year
    Sentry.setTag("school_year", schoolYear);
  }, [schoolYear]);

  const schoolYearClick = (schoolYearSelected) => {
    dispatch(setSchoolYear({ schoolYear: schoolYearSelected, forceUpdate: true }));
  };

  const makeSchoolYearList = (initialSchoolYearList) => {
    const inputSchoolYears = {};

    initialSchoolYearList.forEach(({ parent_access: parentAccess, year }) => {
      inputSchoolYears[year] = `${year}-${year + 1}`;
    });

    return inputSchoolYears;
  };

  const schoolYearList = makeSchoolYearList(schoolYears);

  const buttonContent = () => (
    <>
      <span className={styles.yearButtonText}>
        {schoolYearList[schoolYear]}
      </span>
      <ExpandMoreIcon />
    </>
  );

  return (
    <div>
      {/* <Hidden smDown>
        <span className={styles.yearText}>
          {header.schoolYear.description}
        </span>
      </Hidden> */}
      <HeaderMenu
        items={schoolYearList}
        buttonContent={buttonContent()}
        onClick={schoolYearClick}
        buttonColor="primary"
        buttonVariant="contained"
        buttonClass={styles.yearButton}
      />
    </div>
  );
};

export default SchoolYearSelector;
