import React, { Suspense } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './style';
import { Header } from 'components';

function Layout({ children, isLoading }) {
  const styles = useStyles();

  return (
    <Suspense fallback={<div />}>
      <Header>
        <Grid container direction="row">
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Header>
    </Suspense>
  );
}

Layout.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  isLoading: false,
};
export default Layout;
