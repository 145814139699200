import { combineReducers } from 'redux';
import app from 'store/app/slice';
import auth from 'store/auth/slice';
import adapters from 'store/adapters/slice';
import transforms from 'store/transforms/slice';
import semAnalytics from 'store/semAnalytics/slice';
import widgets from 'store/widgets/slice';

const reducers = {
  app,
  auth,
  // adapters,
  // transforms,
  semAnalytics,
  widgets
};

export default combineReducers(reducers);
